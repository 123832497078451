import { createApp, Vue, ref } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';

// // Create a ref to store the return value
// export const returnValue = ref('Initial Value');

// // Create a function to update the return value
// export function updateReturnValue(newValue) {
//   returnValue.value = newValue;
// }

const i18n = createI18n({
    legacy: false,
});

createApp(App).use(store)
.use(router).use(i18n)
.mount('#app');
