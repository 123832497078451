<template>
        <div class="WorksCard3">
            <p class="title">產品介紹</p>
            <p class="contents">專注特色, 細說獨特之處</p>
                <div class="Card">
                    <div class="video" id="none">
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video1"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(1)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic1.png" alt="Contact Image"/>
                        <p>Corona</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video2"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(2)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic2.png" alt="Contact Image"/>
                        <p>BANANA GO</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video3"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(3)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic3.png" alt="Contact Image"/>
                        <p>One meter | 強力除塵螨機</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video4"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(4)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic4.png" alt="Contact Image"/>
                        <p>One meter | 多功能想食鍋</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video5"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(5)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic5.png" alt="Contact Image"/>
                        <p>One meter | 蒸氣烤箱</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video6"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(6)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic6.png" alt="Contact Image"/>
                        <p>One meter | 微電腦萬用料理鍋</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video7"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(7)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic7.png" alt="Contact Image"/>
                        <p>One meter | 多功能想食鍋</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video8"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(8)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic8.png" alt="Contact Image"/>
                        <p>One meter | 智能陶瓷烘被電暖器</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video9"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(9)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic9.png" alt="Contact Image"/>
                        <p>One meter | 微電腦迷你電子鍋</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video10"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(10)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic10.png" alt="Contact Image"/>
                        <p>手錶</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video11"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(11)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/3/pic11.png" alt="Contact Image"/>
                        <p>One meter | 足部電暖氣</p>
                        <div class="Circle"/>
                    </div>
                </div>
                <div class="WindowVideo" v-if="isVideo" @click="OffVideo()"
                    @keydown="handleKeyDown">
                    <div class="WinVideo1">
                        <div v-for="(video, index) in filteredWindowVideos"
                            :key="index" class="window{{ index + 1 }}">
                            <iframe :src="video" title="Descriptive Title"/>
                        </div>
                    </div>
                </div>
            <div class="DownBackGround"/>
        </div>
</template>

<script>

export default {
    data() {
        return {
            hoveredIndex: null,
            isVideo: false,
            selectedVideoIndex: 0,
            Videos: [
                'https://www.youtube.com/embed/q0UFBcFzIp0?si=yVeROfkgDc87_pA7&autoplay=1&mute=1',
                'https://www.youtube.com/embed/y1dSzgRtDCQ?si=MpJjnwApfM6vOOsf&autoplay=1&mute=1',
                'https://www.youtube.com/embed/ZPUMD_slyeA?si=zPmthbdq5KiPZSki&autoplay=1&mute=1',
                'https://www.youtube.com/embed/5CPFVDkkfmM?si=YK6HZPbubtyusrlo&autoplay=1&mute=1',
                'https://www.youtube.com/embed/PjeSQENI8yk?si=KR5NgDcAY9SlAlPy&autoplay=1&mute=1',
                'https://www.youtube.com/embed/fR3zoqvi_-k?si=LhFqbqzRRxmzFknJ&autoplay=1&mute=1',
                'https://www.youtube.com/embed/6_wMCs6EkcY?si=51kG3r1Ix5Nphajk&autoplay=1&mute=1',
                'https://www.youtube.com/embed/NmVyxoCNV20?si=7aXOrargvOlO44OD&autoplay=1&mute=1',
                'https://www.youtube.com/embed/R5_8cBLCt_w?si=EzCbDv_wfbQhrNoo&autoplay=1&mute=1',
                'https://www.youtube.com/embed/cXt1mP_lZDI?si=A2FShRe9JyEOsxPb&autoplay=1&mute=1',
                'https://www.youtube.com/embed/pjXAS5dR5iE?si=m36asCyAsaSiCdqT&autoplay=1&mute=1',
            ],
        };
    },
    computed: {
        filteredWindowVideos() {
            return this.Videos.filter((_, index) => index === this.selectedVideoIndex);
        },
    },
    methods: {
        togglePlay() {
            const video = document.getElementById('12');
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        },
        openVideo(index) {
            this.isVideo = true;
            this.selectedVideoIndex = index - 1;
        },
        OffVideo() {
            this.isVideo = false;
        },
        handleMouseEnter(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'block';
        },
        handleMouseLeave(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'none';
        },
    },
};
</script>

<style>
.WorksCard3 {
    background-size: 100%;
    background-image: url('/public/data/works/BackGround3.png');
}
.WorksCard3 .title{
    position: absolute;
    padding: 2vw 0 0 10vw;
    color: #F08437;
    font-size: 3vw;
    font-weight: bolder;
}
.WorksCard3 .contents{
    position: absolute;
    padding: 7vw 0 0 10vw;
    font-size: 3vw;
    color: #C0AB9C;
}
@media (max-width: 900px) {
    .WorksCard3 {
        background-image: none;
    }
}
</style>
