<template>
        <div class="WorksCard1">
            <p class="title">廣告影片</p>
            <p class="contents">情感共振, 「影」起共鳴之心</p>
                <div class="Card">
                    <div class="video" id="none">
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video1"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(1)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/1/pic1.png" alt="Contact Image"/>
                        <p>Lemo</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video2"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(2)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/1/pic2.png" alt="Contact Image"/>
                        <p>易碼電控 | 車禍沒有應該</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video3"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(3)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/1/pic3.png" alt="Contact Image"/>
                        <p>One Meter | 微電腦萬用料理鍋</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video4"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(4)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/1/pic4.png" alt="Contact Image"/>
                        <p>奧暢雲服務 Allxon</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video5"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(5)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/1/pic5.png" alt="Contact Image"/>
                        <p>One Meter | 蒸氣烤箱</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video6"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(6)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/1/pic6.png" alt="Contact Image"/>
                        <p>Hen好貸</p>
                        <div class="Circle"/>
                    </div>
                </div>
                <div class="WindowVideo" v-if="isVideo" @click="OffVideo()"
                    @keydown="handleKeyDown">
                    <div class="WinVideo1">
                        <div v-for="(video, index) in filteredWindowVideos"
                            :key="index" class="window{{ index + 1 }}">
                            <iframe :src="video" title="Descriptive Title"/>
                        </div>
                    </div>
                </div>
            <div class="DownBackGround"/>
        </div>
</template>

<script>

export default {
    data() {
        return {
            hoveredIndex: null,
            isVideo: false,
            selectedVideoIndex: 0,
            Videos: [
                'https://www.youtube.com/embed/EeuVrmP1S4M?si=P-0ggdVi-TaUdmFi&autoplay=1&mute=1',
                'https://www.youtube.com/embed/Ee6S6iIycrE?si=auqiR7KuLHpt4Rzj&autoplay=1&mute=1',
                'https://www.youtube.com/embed/fR3zoqvi_-k?si=rm47XmhN-wLMdMZH&autoplay=1&mute=1',
                'https://www.youtube.com/embed/1B2bMQbe6sk?si=g7faTO7ryfkW0yT-&autoplay=1&mute=1',
                'https://www.youtube.com/embed/PjeSQENI8yk?si=0o9OPngA6z8QyzkU&autoplay=1&mute=1',
                'https://www.youtube.com/embed/hbJUkxZSfCk?si=PBCYfr0u9GG99o3g&autoplay=1&mute=1',
            ],
        };
    },
    computed: {
        filteredWindowVideos() {
            return this.Videos.filter((_, index) => index === this.selectedVideoIndex);
        },
    },
    methods: {
        togglePlay() {
            const video = document.getElementById('12');
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        },
        openVideo(index) {
            this.isVideo = true;
            this.selectedVideoIndex = index - 1;
        },
        OffVideo() {
            this.isVideo = false;
        },
        handleMouseEnter(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'block';
        },
        handleMouseLeave(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'none';
        },
    },
};
</script>

<style>
.WorksCard1 {
    background-size: 100%;
    background-image: url('/public/data/works/BackGround1.png');
}
.WorksCard1 .title{
    position: absolute;
    padding: 2vw 0 0 10vw;
    color: #F08437;
    font-size: 3vw;
    font-weight: bolder;
}
.WorksCard1 .contents{
    position: absolute;
    padding: 7vw 0 0 10vw;
    font-size: 3vw;
    color: #C0AB9C;
}
.Card {
    position: relative;
    display: grid;
    grid-template-columns: 33.3vw 33.3vw 33.3vw;
}

.Card > div {
    position: relative;
    text-align: center;
    margin: 1vw 1vw 1vw 2vw;
    width: 90%;
    height: 17vw;
    border-radius: 10px;
    overflow: hidden;
    filter: brightness(90%);
}

.Card > div#none {
    height: 0vw;
}
.Card .video-img {
    width: 120%;
    height: auto;
}
.Card > div:hover {
    filter: brightness(100%);
}
.Card > div p{
    position: absolute;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    height: 5vw;
    font-size: 1.3vw;
    font-weight: bolder;
    color: #9EADFA;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000072;
    backdrop-filter: blur(1px);
}.Circle{
    display: none;
    position: absolute;
    top: 0vw;
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #bbfa9e;
    border: 0.5vw solid #C0AB9C;
    background-color: #0000006a;
    z-index: 1;
}
.Circle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%) rotate(90deg);
  border-left: 1vw solid transparent;
  border-right: 1vw solid transparent;
  border-bottom: 1.7vw solid #C0AB9C;
  z-index: 2;
}
.WindowVideo {
    position: fixed;
    top: 0vw;
    width: 100%;
    height: 100%;
    background:linear-gradient(#00000000 0%,#0000008e 5%,#0000008e 95%,#00000000 100%);
    backdrop-filter: blur(1px);
    overflow: hidden;
    z-index: 20;
}
.WindowVideo  .WinVideo1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    border: none;
}
.WindowVideo  .WinVideo2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    border: none;
}
.WindowVideo .WinVideo1 > div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: none;
}
.WindowVideo .WinVideo1 > div iframe{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
}
.DownBackGround{
    position: relative;
    width: 100vw;
    height: 10vw;
    background: linear-gradient(rgba(0, 0, 0, 0)0%,#242b469b 40%,
            #242b47b4 70%, #293152 100%);
}

@media (max-width: 900px) {
    .WorksCard1 ,.WorksCard2,.WorksCard3,.WorksCard4,.WorksCard5 {
        position: relative;
        top: 10vw;
        background-image: none;
    }
    .title, .contents{
        display: none;
    }
    .Card > div p{
        top: 75%;
        height: 25%;
        font-size: 4vw;
    }
    .Card {
        left: 5vw;
        grid-template-columns:95vw;
    }
     .Card > div {
        height: 45vw;
    }
    .Circle{
        top: 45%;
        left: 45%;
        transform: scale(3);
        display: block;
    }
    .Circle::before {
        top: 50%;
        left: 58%;
    }
    .WindowVideo {
        position: fixed;
        top: 0vw;
        left: 0vw;
        height: 100vh;
        width: 100vw;
        background:#0000007d;
    }
    .WindowVideo  .WinVideo1{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 60vw;
        border: none;
    }/*
    .WindowVideo  .WinVideo2{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 60%;
        border: none;
    }
    .WindowVideo .WinVideo1 > div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border: none;
    }
    .WindowVideo .WinVideo1 > div iframe{
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 10px;
    }*/
}
</style>
