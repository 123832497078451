<template>
  <div class="AboutView">
    <aboutTab/>
    <aboutCard/>
    <ContactContent/>

  </div>
</template>

<script>
import aboutTab from '@/components/AboutTab.vue';
import aboutCard from '@/components/AboutCard.vue';
import ContactContent from '@/components/ContactContent.vue';

export default {
  name: 'AboutView',
  components: {
    aboutTab,
    aboutCard,
    ContactContent,
  },
};
</script>

<style>
.AboutView {
  position: relative;
  overflow: hidden;
  cursor:url('https://www.flaticon.com/free-icon/new-moon_686700?term=circle&related_id=686700');
  overflow-x: hidden;
  background-image: url('/public/data/BackGround/AboutBackGround.png');
  background-size: 100% auto;
}

.about-content {
  position: relative;
  transform: translateY(0);
  transition: transform 0.5s ease;
}

.aboutTab {
  position: relative;
  transform: translateY(0);
  transition: transform 0.5s ease;
}

.contact h2{
  text-decoration: none;
}
@media (max-width: 900px) {
  .WorksView {
    background-image: url('../data/BackGround/Phone/phone_about us.png');
  }
}
</style>
