<template>
    <div class="HomeTab1">
        <h1>服務項目</h1>
        <div class="Button">
          <button class="left" @click="handleLeftButtonClick">
                <img src="../data/Home/arrow-up.png" alt="Contact Image"></button>
          <button class="right" @click="handleRightButtonClick">
                <img src="../data/Home/arrow-up.png" alt="Contact Image">
          </button>
        </div>
        <div class="VideoView">
            <div v-for="(video, index) in videos" :key="index"
                :class="'Card' + (index + 1)" v-show="selectedVideoIndex === index">
                    <div class="Title">
                        <p v-for="line in video.title" :key="line">
                            {{ line }}</p>
                    </div>
                <div class="Text">
                    <p v-for="line in video.text" :key="line">
                        {{ line }}
                    </p>
                </div>
                <video  :ref="`Video${index + 1}`"
                        :class="`Video${index + 1}`" autoplay muted loop playsinline>
                    <source :src="video.src" type="video/mp4">
                </video>

                <div class="VideoSelector">
                    <p v-for="(video, index) in videos" :key="index"
                        @click="ClickVideo(index)"
                        @mouseover="selectVideo(index)"
                        @focus="selectVideo(index)"
                        @keydown.enter="selectVideo(index)"
                        tabindex="0">
                        {{ video.title }}
                    </p>
                </div>
            </div>
        </div>
        <button class="SeeMore">
            <router-link class="link" @click="scrollToContent(1)" to="/Works">
                觀看更多</router-link></button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedVideoIndex: 0,
            videos: [
                {
                    title: '廣告影片',
                    text: ['精心製作，引人注目的廣告影片，讓您的品牌在眾多競爭中脫穎而出。'],
                    src: 'data/video/1.mp4',
                    YT: 'https://www.youtube.com/embed/EeuVrmP1S4M?si=P-0ggdVi-TaUdmFi&autoplay=1&mute=1',
                },
                {
                    title: '形象影片',
                    text: ['呈現公司獨特風采，打造專屬形象影片，深植客戶心中，留下難忘印象。'],
                    src: 'data/video/2.mp4',
                    YT: 'https://www.youtube.com/embed/FmcssNI-S3o?si=P-0ggdVi-TaUdmFi&autoplay=1&mute=1',
                },
                {
                    title: '產品優勢',
                    text: ['生動呈現您的產品優勢，以視覺故事方式，激發客戶購買慾望。'],
                    src: 'data/video/3.mp4',
                    YT: 'https://www.youtube.com/embed/EKT97ri1k2k?si=P-0ggdVi-TaUdmFi&autoplay=1&mute=1',
                },
                {
                    title: '活動紀錄',
                    text: ['細膩捕捉每個時刻，製作活動紀錄影片，讓回顧時流光溢彩。'],
                    src: 'data/video/4.mp4',
                    YT: 'https://www.youtube.com/embed/Q231QiILtQw?si=P-0ggdVi-TaUdmFi&autoplay=1&mute=1',
                },
                {
                    title: '人物專訪',
                    text: ['深入探訪，挖掘故事背後，打造感人人物專訪，展現真實動人的一面。'],
                    src: 'data/video/5.mp4',
                    YT: 'https://www.youtube.com/embed//5d1LWDYlDgU?si=P-0ggdVi-TaUdmFi&autoplay=1&mute=1',
                },
            ],
        };
    },
    methods: {
        playVideo(videoClassName) {
            const selectedVideo = this.$refs[videoClassName][0];
            if (selectedVideo) {
                selectedVideo.currentTime = 0;
                selectedVideo.play();
            }
        },
        selectVideo(index) {
            this.selectedVideoIndex = index;
            this.playVideo(`Video${index + 1}`);
        },
        ClickVideo(index) {
            // this.zoomedIndex = 2;
            // this.$router.push({
            //     name: 'project',
            //     hash: '#WorksContent',
            // });
        },
        scrollToContent(newIndex) {
        const contentElement = document.getElementById('Content');
        if (contentElement) {
            contentElement.scrollIntoView({ behavior: 'smooth' });
        }
        },
    handleLeftButtonClick() {
this.selectedVideoIndex = (this.selectedVideoIndex - 1 + this.videos.length) % this.videos.length;
    },

    handleRightButtonClick() {
this.selectedVideoIndex = (this.selectedVideoIndex + 1) % this.videos.length;
    },
    },
};
</script>

<style>
.HomeTab1 {
    padding: 0;
    height: 72vw;
    position: relative;
    overflow: hidden;
}
.HomeTab1 .Button{
    display: none;
}
.HomeTab1 h1{
    position: relative;
    top: 15vw;
    color: #FFB37F;
    font-size: 3vw;
    font-weight: bolder;
    margin: 0 0 4vw 0;
}
.VideoView {
    position: relative;
    top: 48%;
    left: 43%;
    transform: translate(-50%, -50%);
    height: 40vw;
    width: 57vw;
    border-radius: 2vw;
    background-color: black;
}

.VideoView .Card1 ,.Card2 ,.Card3 ,.Card4 ,.Card5{
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 2vw;
    background-color: rgb(0, 0, 0);
    z-index: 0;
}
.VideoView .Text{
    position: absolute;
    bottom: 0;
    height: 20%;
    width: 100%;
    overflow: hidden;
    color: #9EADFA;
    font-weight:bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8vw 0 8vw;
    border-bottom-right-radius: 2vw;
    border-bottom-left-radius: 2vw;
    background-color: rgba(0, 0, 0, 0.688);
    z-index: 1;
}
.VideoView .Title {
    display: none;
}
.VideoView video {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}
.VideoView .YT iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}
.VideoView .YT iframe{
    width: 57vw;
    z-index: 5;
}
.VideoSelector {
    position: absolute;
    top: 3vw;
    left: 76vw;
    transform: translateX(-50%);
    justify-content: space-between;
    width: 80%;
    margin-top: 1vw;
    z-index: -10;
}

.VideoSelector p {
    width: 15vw;
    height: 4vw;
    margin: 1.5vw 0 0 0;
    left: 1vw;
    color: #c0ab9c;
    font-size: 1.5vw;
    font-weight:bold;
    border-top-right-radius: 15px;
    background-color: #384162;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.VideoSelector p:hover {
    color: #FFB37F;
    margin-left: 2vw;
}
@media (max-width:900px) {
    .HomeTab1 {
        height: 200vw;
    }
    .HomeTab1 .Button{
        position: absolute;
        display: block;
        width: 100vw;
        top: 65vw;
        z-index: 5;
    }
    .Button button {
        position: absolute;
        top: 22vw;
        width: 10vw;
        height: 10vw;
        font-size: 5vw;
        font-weight: bolder;
        color: #384162;
        background-color: #ffffffa6;
        cursor: pointer;
        border: none;
        border-radius: 10vw;
    }
    .Button .left {
        left: 3vw;
    }
    .Button .right {
        right: 3vw;
    }
    .HomeTab1 h1{
        font-size: 6vw;
    }
    .VideoView {
        top: 43%;
        left: 50%;
        width: 90vw;
        height: 125vw;
        background-color: #00000000;
    }
    .VideoView .Title {
        position: absolute;
        display: block;
        width: 100%;
        height: 23vw;
        font-size: 6vw;
        font-weight: bold;
        padding: 2vw 0 0 0;
        color: #9EADFA;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #00000000;
    }
    .VideoView video{
        width: 150%;
        display: flex;
        overflow-x: auto;
    }
    .VideoView .Card1, .Card2, .Card3, .Card4, .Card5{
        position: relative;
        overflow: hidden;
        display: flex;
        background-color: #00000045;
    }
    .VideoSelector {
        display: none;
        top: 3vw;
        left: 0vw;
        transform: translateX(0%);
        justify-content: space-between;
        width: 100%;
        margin-top: 1vw;
        z-index: 0;
    }

    .HomeTab1 .Button .left {
        left: 3vw;
    }
    .HomeTab1 .Button .left img {
        position: relative;
        right: 0.15vw;
        width: 4vw;
        height: 3vw;
        transform: rotate(270deg);
    }
    .HomeTab1 .Button .right {
        right: 3vw;
    }
    .HomeTab1 .Button .right img {
        position: relative;
        right: -0.15vw;
        width: 4vw;
        height: 3vw;
        transform: rotate(90deg);
    }
    .VideoSelector p {
        position: absolute;
        width: 100%;
        height: 10vw;
        font-size: 6vw;
        padding: 2vw 0 0 0;
        color: #9EADFA;
        background-color: #00000000;
    }
    .HomeTab1 .SeeMore {
        position: relative;
        top: 28vw;
        width: 35vw;
        height: 13vw;
        color: #384162;
        padding: 1vw 3vw 1vw 3vw;
        border-radius: 4vw;
        font-weight: bolder;
        border: 1px solid #ffffff;
        font-size: 4vw;
        background-color: #FFB37F;
    }
    .SeeMore .link {
        color: #384162;
        text-decoration: none;
    }
}
</style>
