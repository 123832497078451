<template>
    <div class="partner-tittle">
        <h1 class="AboutPartner">合作廠商</h1>
        <div class="marquee-container">
            <div class="marquee-container1">
                <div v-for="(classification, index) in classifications"
                    :key="index" class="partner-photo">
                    <div class="partner-photo1"
                        :style="{
                            'background-image': `url(${classification.img})`,
                            'background-size': 'cover',
                            'background-position': 'center',}">
                    </div>
                </div>
            </div>
            <div class="marquee-container2">
                <div v-for="(classification2, index) in classifications2"
                    :key="index" class="partner-photo">
                    <div class="partner-photo2"
                        :style="{
                            'background-image': `url(${classification2.img})`,
                            'background-size': 'cover',
                            'background-position': 'center',
                        }">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';

export default {
    data() {
        return {
            classifications: [
                { id: 1, name: 'Vtex', img: './data/Clients/1/1-1.png' },
                { id: 2, name: 'XXX', img: './data/Clients/1/1-2.png' },
                { id: 3, name: 'XXX', img: './data/Clients/1/1-3.png' },
                { id: 4, name: 'Vtex', img: './data/Clients/1/1-4.png' },
                { id: 5, name: 'XXX', img: './data/Clients/1/1-5.png' },
                { id: 6, name: 'XXX', img: './data/Clients/1/1-6.png' },
                { id: 7, name: 'XXX', img: './data/Clients/1/1-7.png' },
                { id: 8, name: 'Vtex', img: './data/Clients/1/1-8.png' },
                { id: 9, name: 'XXX', img: './data/Clients/1/1-9.png' },
                { id: 10, name: 'XXX', img: './data/Clients/1/1-10.png' },
            ],
            classifications2: [
                { id: 1, name: 'Vtex', img: './data/Clients/2/2-1.png' },
                { id: 2, name: 'XXX', img: './data/Clients/2/2-2.png' },
                { id: 3, name: 'XXX', img: './data/Clients/2/2-3.png' },
                { id: 4, name: 'Vtex', img: './data/Clients/2/2-4.png' },
                { id: 5, name: 'XXX', img: './data/Clients/2/2-5.png' },
                { id: 6, name: 'XXX', img: './data/Clients/2/2-6.png' },
                { id: 7, name: 'XXX', img: './data/Clients/2/2-7.png' },
                { id: 8, name: 'Vtex', img: './data/Clients/2/2-8.png' },
                { id: 9, name: 'XXX', img: './data/Clients/2/2-9.png' },
            ],
        };
    },
    methods: {
        redirectToAnotherPage() {
            router.push('./contact');
        },
    },

};
</script>

<style>
.partner-tittle {
    height: 45vw;
    color: white;
    text-align: left;
}
.partner-tittle .AboutPartner {
    position: relative;
    font-size: 3vw;
    text-align: center;
    color: #FFB37F;
}

.marquee-container{
    position: relative;
    top: 5vw;
    left: 10vw;
    width: 80vw;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.marquee-container1,.marquee-container2 {
    display: flex;
}

.partner-photo1, .partner-photo2 {
  flex: 0 0 10vw;
  height: 15vw;
  width: 15vw;
  margin: 0vw 5vw;
  background-size: cover;
  background-position: center;
}
.partner-photo1 {
  animation: scrollMarquee1 50s linear infinite;
}
.partner-photo2 {
  animation: scrollMarquee2 50s linear infinite;
}
@keyframes scrollMarquee1 {
 0% {
    transform: translateX(600%);
  }
  50% {
    transform: translateX(-600%);
  }
  100% {
    transform: translateX(600%);
  }
}
@keyframes scrollMarquee2 {
 0% {
    transform: translateX(-450%);
  }
  50% {
    transform: translateX(500%);
  }
  100% {
    transform: translateX(-450%);
  }
}

  @media (max-width: 900px) {
    .partner-tittle {
        position: relative;
        top: 12vw;
        height: 100vw;
    }
    .marquee-container {
        top: 13vw;
    }
    .partner-tittle .AboutPartner {
        top: 6vw;
        font-size: 6vw;
    }

    .partner-photo1, .partner-photo2 {
        height: 25vw;
        width: 25vw;
    }
  }
</style>
