<template>
  <header>
    <main>
      <ListItem
      :name=1 />
    </main>
    <!-- :class="{ transparent: activeBtn === 0 }" -->
    <div class="Top" v-show="isHeaderTransparent">

      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
      <router-link to="/" class="brand"
        :class="{ active: activeBtn === 0 }"
        @click="sliderNav(0)">
        <img width="10754" height="4867" src="../public/data/BackGround/logo.png" alt="Logo" />
      </router-link>

      <nav :class="{ 'navigation active': isMenuActive, 'navigation': !isMenuActive }">
        <div class="navigation-items">
        <div class="Tittle">
          <router-link to="/" id="homeBtn" class="nav-btn"
            :class="{ active: activeBtn === 1 }" @click="sliderNav(1)">
            HOME
          </router-link>
          <router-link to="/Works" id="works" class="nav-btn"
            :class="{ active: activeBtn === 2 }" @click="sliderNav(2)"
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"
            @focusin="handleMouseLeave"
            @focusout="handleMouseLeave"
            @keydown="handleKeyDown">
            WORKS
          </router-link>
          <router-link to="/about" id="about" class="nav-btn"
            :class="{ active: activeBtn === 3 }" @click="sliderNav(3)">
            ABOUT US
          </router-link>
          <router-link to="/contact" id="contact" class="nav-btn"
            :class="{ active: activeBtn === 4 }" @click="sliderNav(4)">
            CONTACT
          </router-link>
          <a href="https://www.youtube.com/@rogershen81411" target="_blank">
            <img src="./data/Top/YT.png" alt=" ">
          </a>
          <a href="https://line.me/R/ti/p/@mjz1813g"
                      onclick="return gtag_report_line()"
                      target="_blank">
            <img src="./data/Top/Line.png" alt=" ">
          </a>

          <div class="WorksAll" v-if="activeBtn === 2">
            <router-link @click="scrollToContent(1)" to="/Works">廣告影片</router-link>
            <router-link @click="scrollToContent(2)" to="/Works">形象影片</router-link>
            <router-link @click="scrollToContent(3)" to="/Works">產品介紹</router-link>
            <router-link @click="scrollToContent(4)" to="/Works">活動紀錄</router-link>
            <router-link @click="scrollToContent(5)" to="/Works">人物專訪</router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <div class="CTAweb" v-show="showScrollButton">
    <div class="web" >
      <a href="tel:+88621910131" target="_blank"
                        onclick="return gtag_report_call()">
        <div  class="Call"/>
        <p>電話接洽</p>
      </a>
    </div>
    <router-link to="/contact" id="contact" class="web"
      :class="{ active: activeBtn === 4 }" @click="sliderNav(4)">
          <div  class="form"/>
        <p>聯絡表單</p>
    </router-link>
    <div class="web">
      <a href="https://line.me/R/ti/p/@mjz1813g"
                      onclick="return gtag_report_line()"
                      target="_blank">
          <div  class="Line"/>
        <p>免費諮詢</p>
      </a>
    </div>
  </div>
<div class="CTAwebBackGround"/>
  <div class="ToTop" v-show="showScrollButton">
    <button @click="scrollToTop" class="scroll-button" >
      <img src="./data/Home/arrow-up.png" alt="Contact Image">
    </button>
  </div>
  <div class="icons">
    <div id="menu-btn"
      :class="{ 'fas fa-bars': !isMenuActive, 'fas fa-times': isMenuActive,
      show: isNavigationVisible}"
      @click="toggleMenu"
      @keydown="handleKeyboardEvent"
      tabindex="0"
      ref="menuBtn"/>
  </div>
  </header>
  <router-view/>
  <footerTab/>
</template>

<script>
  import { watch, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import footerTab from '@/components/FooterSection.vue';

  export default {
    components: {
      footerTab,
    },
  data() {
    return {
      isHeaderTransparent: true,
      isMenuActive: false,
      activeBtn: 1,
      showScrollButton: false,
      WorkCard: 2,
      zoomedIndex: null,
      worksContentRef: null,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToContent(newIndex) {
      const contentElement = document.getElementById('Content');
      this.zoomedIndex = newIndex;
      console.log('this.zoomedIndex:', this.zoomedIndex);
      if (this.worksContentRef) {
        this.worksContentRef.CardIndex = newIndex;
      }
      if (contentElement) {
        contentElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    sliderNav(manual) {
        this.activeBtn = manual;
        if (manual !== 0) {
          this.isMenuActive = !this.isMenuActive;
          this.activeBtn = manual;
        }
    },
    setLang(value) {
      console.log('setLang');
      this.$store.commit('setLang', value);
      this.$i18n.locale = value;
      localStorage.setItem('footmark-lang', value);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (!isMobileDevice) {
        if (window.pageYOffset > 300) {
          this.isHeaderTransparent = false;
          this.showScrollButton = true;
        } else {
          this.isHeaderTransparent = true;
          this.showScrollButton = false;
        }
      } else if (isMobileDevice) {
        if (window.pageYOffset > 300) {
          this.showScrollButton = true;
        } else {
          this.showScrollButton = false;
        }
      }
    },
  },
  setup() {
  const store = useStore();

    const { t, locale } = useI18n();

    watch(locale, (newlocale) => {
      localStorage.setItem('locale', newlocale);
    });
  },
};
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header{
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1em; font-weight: bold;
  transition: 0.5s ease;
  background-color:#f8f8f800;
}
.Top{
  width: 100vw;
}
header .navigation .navigation-items .nav-btn ,header .navigation .navigation-items .WorksAll a {
  position: relative;
  font-size: 1.5vw;
  font-weight: 600;
  width: 10vw;
  border-radius: 5px;
  text-decoration: none;
  color: #C0AB9C;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .navigation .navigation-items .WorksAll a {
  height: 3vw;
}
header .navigation .navigation-items .nav-btn.active ,
header .navigation .navigation-items .WorksAll a.active {
  color: #F08437;
}
.navigation-items .Tittle .nav-btn {
  height: 5vw;
}
.CTAweb{
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 11vh;
  right: 0vw;
  width: 7vw;
  height: 20vw;
  border-radius: 1vw;
  background-color: #C0AB9C;
}
.CTAweb .web {
  position: relative;
  width: 88%;
  height: 30%;
  padding: 0.5vw 0 0.5vw 0;
  margin: 0.3vw;
  color: #384162;
  border-radius: 1vw;
  background-color: #FFDFC8;
}
.CTAweb .Call ,.CTAweb .form,.CTAweb .Line{
  position: relative;
  top: 10%;
  left: 30%;
  width: 2.5vw;
  height: 2.7vw;
  background-size: cover;
  background-position: center;
}
.CTAweb .Call{
    background-image: url('./data/App/CTA/Computer/Call.png');
}
.CTAweb .form{
    background-image: url('./data/App/CTA/Computer/form.png');
}
.CTAweb .Line{
    background-image: url('./data/App/CTA/Computer/Line.png');
}
.CTAweb .web:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.354);
}
.CTAweb img{
  margin: 0.5vw 0vw 0 0;
  width: 2.5vw;
}

.CTAweb a{
  text-decoration: none !important;
  font-weight: bolder;
  color: #384162;
}
.CTAweb p{
  position: relative;
  top: 0.7vw;
  font-size: 1.2vw;
  margin: 0.1vw 0vw 0 0;
}
.ToTop{
  position: fixed;
  bottom: 2vh;
  right: 1vw;
  width: 4vw;
  height: 4vw;
  border-radius: 50vw;
  border: 0.2vw solid #FFDFC8;
  background-color: #C0AB9C;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ToTop .scroll-button{
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
}
.ToTop .scroll-button img{
  width: 2vw;
}
.brand{
  margin-left: -3.3vw;
}

.brand img{
    position: absolute;
    top: 2vw;
    left: 3.3vw;
    width: 18vw;
    height: 5.3vw;
}

header .icons {
  display: none;
}

header .navigation{
  position: relative;
}

.langBtn .separator {
  margin: 0 0.5vw;
  color: #ffffff;
}

.navigation-items #homeBtn:hover ,#works:hover ,#about:hover ,#contact:hover {
  box-shadow: inset 0 6px 12px rgba(0, 0, 0, 0.618);
  background-color: rgba(0, 0, 0, 0.375);
}
.navigation-items .WorksAll{
  position: absolute;
  top: 6vw;
  right: 28vw;
  width: 10vw;
  height: 15.5vw;
  border-radius: 1vw;
  box-shadow: inset 0 6px 12px rgba(0, 0, 0, 0.618);
  background-color: rgba(0, 0, 0, 0.375);
  display: none;
}
.navigation-items .WorksAll .nav-btn{
  height: 3vw;
  padding: 0.5vw 0 0 0;
}
header .navigation .navigation-items #YT ,#Line {
  position: relative;
  font-size: 1.5vw;
  font-weight: 600;
  width: 6vw;
  border-radius: 5px;
  text-decoration: none;
  color: #C0AB9C;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .navigation .navigation-items #Home{
  display: none;
}

header .navigation .navigation-items button{
  position: relative;
  font-size: 1.2vw; font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  border: #ffffff00;
  background-color: #a2a2a200;
}

header .navigation .navigation-items .langBtn{
  position: relative;
  margin-right: 0vw
}

header .navigation .pointerHover{
  cursor: pointer;
}

.navigation-items .Tittle{
  position: relative;
  top: 2.5vw;
  right: 0vw;
  height: 8vw;
  display: flex;
  justify-content: flex-end;
}
.Tittle a img{
  position: relative;
  top: 1vw;
  width: 3vw;
  margin: 0 1vw 0 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}
nav {

  a {
    font-weight: bold;
    color: #ffffff;

    &.router-link-exact-active {
      color: #ffffff;
    }
  }
}

@media (max-width: 900px) {
  .Top{
    width: 110vw;
    height: 34vw;
    background-color: #2e3053;
  }
}
@media only screen and (max-width: 767px) {
  header .icons {
    position: absolute;
    top: 155%;
    right: 4vw;
    width: 10vw;
    height: 10vw;
    color: #C0AB9C;
    display: block;
  }
.brand img {
    top: 120%;
    left: 6.3vw;
    width: 25.5vw;
    height: 8.5vw;
}
.fa, .fas {
  font-size: 6vw;
}
  header .navigation .navigation-items .nav-btn{
    font-size: 6vw;
    width: 60vw;
    padding: 10vw 0 10vw 0;
    color: #384162;
  }
  header .navigation .navigation-items .nav-btn.active {
    color: #F08437;
    background-color: #ffffff;
  }
  .fa-times:before{
    position: relative;
    color: #384162;
    right: 30vw;
    z-index: 1vw;
  }
  .navigation {
    position: fixed;
    top: 0;
    left: 0vw;
    width: 70vw;
    height: 110vh;
    color: #1e1f34;
    background-color: #C0AB9C;
    overflow-y: auto;
    display: none;
  }

  .navigation.active {
    display: block;
  }

  .navigation-items {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 20vw;
    padding: 1vw;
  }
  .navigation-items .Tittle{
    height: 50vw;
    flex-direction:column;
    justify-content: space-between;
  }
  .navigation-items .WorksAll{
    top: 25vw;
    right: 3vw;
    width: 90%;
    height: 100%;
    display: none;
    background-color: #ffffff;
  }
  header .navigation .navigation-items .WorksAll .nav-btn{
    top: 10vw;
    left: 0vw;
    width: 100%;
    font-size: 5vw;
    padding: 4.5vw 0 0 0;
  }
  .nav-btn {
    margin-bottom: 15px;
  }
  .CTAweb {
    position: fixed;
    top:90vh;
    left: 0;
    height: 25vw;
    bottom: 0vw;
    width: 100vw;
    border-radius: 4vw 4vw 0 0;
    background-color: #384162;
    display: flex;
    flex-direction:row;
    justify-content: space-around;
    align-items: center;
  }
  .CTAweb .web{
    top: 3vw;
    width: 30vw;
    height: 20vw;
    background-color: #00000000;
  }
  .CTAweb .Call ,.CTAweb .form,.CTAweb .Line{
    position: relative;
    top: 0vw;
    left: 35%;
    width: 8vw;
    height: 8vw;
    background-size: 8vw;
    background-position: center;
  }
  .CTAweb .Call{
      background-image: url('./data/App/CTA/Phone/Call.png');
  }
  .CTAweb .form{
      background-image: url('./data/App/CTA/Phone/form.png');
  }
  .CTAweb .Line{
      background-image: url('./data/App/CTA/Phone/Line.png');
  }
  .CTAweb p{
    font-size: 4vw;
    color: #FFDFC8;
  }
  .CTAwebBackGround {
    position: fixed;
    bottom: 0vw;
    width: 100vw;
    height: 25vw;
    background: linear-gradient(#00000000 0%,#00000041 15%,#0000004c 30%,
              #000000 90%,#000000 100%);
    z-index: -1;
  }
  .ToTop {
    bottom: 25vw;
    right: 5vw;
    width: 9vw;
    height: 9vw;
  }
  .ToTop .scroll-button img{
    width: 5vw;
  }
}

::-webkit-scrollbar-track {
  background-color: #1e1f34;
}
::-webkit-scrollbar {
  width: 0.5vw;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #463b31cb;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #2e3053;
}
</style>
