<template>
    <div class="about aboutCard">
        <div class="tittle">
            <h1>合作流程</h1>
        </div>
        <div class="justify-content-center aboutCont">
            <div class="row cooperate-card-center ">
                <div class="cooperate-photo-01">
                    <div class="box-photo" id="photo01"/>
                    <div class="box-photo" id="photo02"/>
                    <div class="box-photo" id="photo03"/>
                </div>
                <div class="cooperate-photo-02">
                    <div class="box-photo" id="photo04"/>
                    <div class="box-photo" id="photo05"/>
                    <div class="box-photo" id="photo06"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style>
.aboutCard {
    padding: 2% 0% 12.5%;
}
.aboutCard .tittle h1 {
    text-align: center;
    font-size: 3vw;
    color: #FFB37F;
    margin: 0 0 2vw 0;
}

.aboutCont {
    display: flex;
    justify-content: center;
}
.cooperate-photo-01 ,.cooperate-photo-02 {
  display: flex;
}
.box-photo{
    width: 18vw;
    height: 26vw;
    margin-top: 3vw;
    margin-right: 2.5vw;
    margin-left: 2.5vw;
    background-size: cover;
    background-position: center;
}
.aboutCard #photo01 {
    background-image: url('../data/AboutCard/Computer/Step1.png');
}
.aboutCard #photo02 {
    background-image: url('../data/AboutCard/Computer/Step2.png');
}
.aboutCard #photo03 {
    background-image: url('../data/AboutCard/Computer/Step3.png');
}
.aboutCard #photo04 {
    background-image: url('../data/AboutCard/Computer/Step4.png');
}
.aboutCard #photo05 {
    background-image: url('../data/AboutCard/Computer/Step5.png');
}
.aboutCard #photo06 {
    background-image: url('../data/AboutCard/Computer/Step6.png');
}

@media (max-width: 900px) {
    .cooperate-photo-01 ,.cooperate-photo-02 {
    display: block;
    }
    .aboutCard {
        height: 292vw;
        margin: 18vw 0 0 0;
    }
    .aboutCard .tittle h1{
        font-size: 6vw;
    }
    .box-photo{
        width: 82vw;
        height: 40vw;
        margin-top: 5vw;
        margin-right: 2.5vw;
        margin-left: 2.5vw;
    }
    .aboutCard #photo01 {
        background-image: url('../data/AboutCard/Phone/Step1.png');
    }
    .aboutCard #photo02 {
        background-image: url('../data/AboutCard/Phone/Step2.png');
    }
    .aboutCard #photo03 {
        background-image: url('../data/AboutCard/Phone/Step3.png');
    }
    .aboutCard #photo04 {
        background-image: url('../data/AboutCard/Phone/Step4.png');
    }
    .aboutCard #photo05 {
        background-image: url('../data/AboutCard/Phone/Step5.png');
    }
    .aboutCard #photo06 {
        background-image: url('../data/AboutCard/Phone/Step6.png');
    }
}
</style>
