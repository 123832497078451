<template>
    <div class="AboutTab">
        <img src="../data/Home/AboutUS.png" alt="Contact Image">
        <div class="Title">
            <h1>關於有溫度</h1>
            <p>
                <span style="color: #FFB37F;">有溫度為一支專業的影像製作團隊</span>
                <span style="color: #FFDFC8;">，我們</span>
                <span style="color: #FFB37F;">致力於打造最高品質的影片</span>
                <span style="color: #FFDFC8;">，範疇包括廣告、戲劇、微電影、活動紀錄、形象影片、音樂錄影帶等。<br><br></span>

                <span style="color: #FFDFC8;">透過多年累積的經驗，有溫度不僅擁有大量海內外的優專業水準，更</span>
                <span style="color: #FFB37F;">不斷創新應用新的拍攝剪輯手法。<br><br></span>

                <span style="color: #FFDFC8;">與其他製作公司的區別在於，我們不僅注重影片製作的質作品，更</span>
                <span style="color: #FFB37F;">強調將客戶的真摯感受融入每個作品中。<br><br></span>

                <span style="color: #FFDFC8;">在競爭激烈的市場中，</span>
                <span style="color: #FFB37F;">選擇我們，讓您的品牌影像脫穎而出，贏得目標受眾的心！</span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
};
</script>

<style>
.AboutTab {
    position: relative;
    top: 10vw;
    height: 50vw;
    padding: 0%;
}
.AboutTab img{
    position: absolute;
    width: 65%;
    left: 0;
}
.AboutTab .Title{
    position: absolute;
    top: 9vw;
    right: 10vw;
    font-size: 1.8vw;
    font-weight: bolder;
    text-align: right;
    color: #FFB37F;
}
.AboutTab .Title p{
    position: relative;
    top: 1vw;
    left: 3vw;
    width: 24vw;
    font-size: 1vw;
    text-align: left;
}
  @media (max-width: 900px) {
    .AboutTab{
        height: 190vw;
    }
    .AboutTab img {
        position: absolute;
        width: 100vw;
        left: 0;
    }
    .AboutTab .Title{
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85vw;
        height: 130vw;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.43);
    }
    .AboutTab .Title h1, .AboutTab .Title p{
        position: absolute;
        transform: translate(15%, 25%);
        top: 4vw;
        left: 0vw;
        width: 67vw;
    }
    .AboutTab .Title h1{
        top: 4vw;
        right: 10vw;
        font-size: 6vw;
        text-align: center;
    }
    .AboutTab .Title p{
        top: -5vw;
        font-size: 4vw;
        font-weight: lighter;
        text-align: left;
    }
  }
</style>
