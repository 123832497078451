<template>
  <div class="HomeView">
    <homeTab/>
    <homeTab1/>
    <homeTab3/>
    <AboutCard/>
    <ContactContent/>
    <HomePartner/>
  </div>
</template>

<script>
import homeTab from '@/components/HomeTab.vue';
import homeTab1 from '@/components/HomeTab1.vue';
import homeTab3 from '@/components/HomeTab3.vue';
import AboutCard from '@/components/AboutCard.vue';
import ContactContent from '@/components/ContactContent.vue';
import HomePartner from '@/components/HomePartner.vue';

export default {
  name: 'HomeView',
  components: {
    homeTab,
    homeTab1,
    homeTab3,
    AboutCard,
    ContactContent,
    HomePartner,
},
};
</script>

<style>

.HomeView {
  cursor:url('https://www.flaticon.com/free-icon/new-moon_686700?term=circle&related_id=686700');
  overflow-x: hidden;
  background-image: url('../data/BackGround/HomeBackGround.png');
  background-size: 100% auto;
}
.home {
  padding: 6% 0% 0%;

}
@media (max-width: 900px) {
  .WorksView {
    background-image: url('../data/BackGround/Phone/phone_home.png');
  }
}
</style>
