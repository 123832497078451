<template>
    <div class="hometab3 tittlecard">
        <h1>方案內容</h1>
            <div class="pic">
                <div class="Home3" id="Home3-0"/>
            </div>
            <div class="pp">
                <div class="View">
                    <div class="Home3" id="Home3-1"/>
                    <div class="Home3" id="Home3-2"/>
                    <div class="Home3" id="Home3-3"/>
                </div>
            </div>
            <div class="RightView"/>
        <p>以上各配置為基本參考，皆可依客戶腳本需求做調整。</p>
        <p>實際報價會依討論完腳本細節後做最後報價。</p>
    </div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.2/vanilla-tilt.min.js';
        script.async = true;
        document.body.appendChild(script);
    },
};
</script>

<style>
.hometab3 {
    position: relative;
    height: 90vw;
    overflow: hidden;
    margin: 2vw 0 0 0;
}
.hometab3.tittlecard h1{
    text-align: center;
    font-size: 3vw;
    color: #FFB37F;
    margin: 0 0 4vw 0;
}
.View {
    position: absolute;
    height: 100vh;
    top: 8vw;
    left: 30vw;
    display: flex;
}
.pic{
    position: relative;
    height: 65vw;
    left: 50%;
    transform: translate(-50%, 0);
    width: 70vw;
    border-top-left-radius: 2vw 3vw;
    border-bottom-left-radius: 2vw 3vw;
    border-top-right-radius: 2vw 3vw;
    border-bottom-right-radius: 2vw 3vw;
    overflow: hidden;
    display: flex;
}
.pic .Home3 ,.View .Home3 {
    width: 18vw;
    height: 65vw;
    margin: 0 0.5vw 0 0.5vw;
    background-size: cover;
    background-position: center;
}
.pic #Home3-0 {
    width: 14vw;
    height: 65vw;
    margin: 0 0.5vw 0 0.5vw;
    background-size: cover;
    background-position: center;
    background-image: url('../data/Home/Home3/Computer/0/0.png');
}
#Home3-1 {
    background-image: url('../data/Home/Home3/Computer/0/1.png');
}
#Home3-1:hover {
    background-image: url('../data/Home/Home3/Computer/1/1.png');
}
#Home3-2 {
    background-image: url('../data/Home/Home3/Computer/0/2.png');
}
#Home3-2:hover {
    background-image: url('../data/Home/Home3/Computer/1/2.png');
}
#Home3-3 {
    background-image: url('../data/Home/Home3/Computer/0/3.png');
}
#Home3-3:hover {
    background-image: url('../data/Home/Home3/Computer/1/3.png');
}
.hometab3 p{
    position: relative;
    top: 2vw;
    font-size: 1.2vw;
    font-weight: bold;
    color: #C0AB9C;
}

@media (max-width: 900px) {
    .hometab3 {
        height: 200vw;
    }
    .hometab3.tittlecard h1{
        font-size: 6vw;
    }
    .hometab3 p {
        top: 13.5vw;
    }
    .pic {
        top: 26vw;
        left: 18vw;
        width: 19vw;
        height: 164VW;
    }
    .pp{
        position: relative;
        top: -162vw;
        left: 25vw;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;}
    .hometab3 .RightView {
            position: absolute;
            top: 25vw;
            height: 85%;
            width: 10vw;
            right: 0vw;
            background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%,
            #1e233a68 80%, #1E233A);

        }
    .View {
        position: absolute;
        top: 12vw;
        left: 0vw;
        width: 196vw;
        height: 164vw;
        padding: 0 30vw 0 0;
    }
    .View .Home3 {
        width: 33%;
        height: 100%;
        margin: 0 1.5vw 1.5vw 0;
        background-size: cover;
        background-position: center;
    }
    .pic #Home3-0 {
        position: relative;
        top: -12vw;
        height: 100%;
        margin: 0 0.5vw 0 0.5vw;
        background-size: cover;
        background-position: center;
        background-image: url('../data/Home/Home3/Phone/0.png');
    }
    #Home3-1 {
        background-image: url('../data/Home/Home3/Phone/1.png');
    }
    #Home3-1:hover {
        background-image: url('../data/Home/Home3/Phone/1.png');
    }
    #Home3-2 {
        background-image: url('../data/Home/Home3/Phone/2.png');
    }
    #Home3-2:hover {
        background-image: url('../data/Home/Home3/Phone/2.png');
    }
    #Home3-3 {
        background-image: url('../data/Home/Home3/Phone/3.png');
    }
    #Home3-3:hover {
        background-image: url('../data/Home/Home3/Phone/3.png');
    }
}
</style>
