<template>
    <div class="WorksTab">
        <div class="image-container"/>
    </div>
</template>

<style>
.WorksTab {
    position: relative;
    width: 100%;
    height: 60vw;
}
.WorksTab .image-container {
    width: 100vw;
    height: 60vw;
    background-image: url('../data/Work/top.png');
    background-size: cover;
    background-position: center;
}

@media (max-width: 900px) {
    .WorksTab {
        width: 100%;
        height: 140vw;
    }
        .WorksTab .image-container {
            position: relative;
            width: 100vw;
            height: 140vw;
            top: 19vw;
            background-image: url('../data/Work/top-Phone.png');
            background-size: cover;
            background-position: center;
        }
}
</style>
