<template>
        <div class="WorksCard2">
            <p class="title">形象影片</p>
            <p class="contents">每一幀, 都是品牌與眾間的對話</p>
                <div class="Card">
                    <div class="video" id="none">
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video1"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(1)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic1.png" alt="Contact Image"/>
                        <p>Cytiva</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video2"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(2)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic2.png" alt="Contact Image"/>
                        <p>水澐靝</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video3"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(3)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic3.png" alt="Contact Image"/>
                        <p>Yellow Metal</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video4"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(4)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic4.png" alt="Contact Image"/>
                        <p>澎湖</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video5"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(5)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic5.png" alt="Contact Image"/>
                        <p>ZAP Hair salon 全日式美髮院</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video6"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(6)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic6.png" alt="Contact Image"/>
                        <p>搬鼓人 | 奈米電影</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video7"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(7)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic7.png" alt="Contact Image"/>
                        <p>體育署</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video8"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(8)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic8.png" alt="Contact Image"/>
                        <p>巨琛總部 | 室內動畫</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video9"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(9)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic9.png" alt="Contact Image"/>
                        <p>奧暢雲服務 | Allxon</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video10"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(10)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic10.png" alt="Contact Image"/>
                        <p>岳鼎 XMIGHT</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video11"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(11)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic11.png" alt="Contact Image"/>
                        <p>信任網路BiiLabs</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video12"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(12)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic12.png" alt="Contact Image"/>
                        <p>易碼電控 | 車禍沒有應該</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video13"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(13)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic13.png" alt="Contact Image"/>
                        <p>瑞比智慧科技</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video14"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(14)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic14.png" alt="Contact Image"/>
                        <p>香港商女媧創造</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video15"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(15)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic15.png" alt="Contact Image"/>
                        <p>台灣資料科學</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video16"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(16)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic16.png" alt="Contact Image"/>
                        <p>昊慧 AI Clerk</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video17"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(17)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic17.png" alt="Contact Image"/>
                        <p>憶象 Memorence AI</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video18"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(18)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic18.png" alt="Contact Image"/>
                        <p>Hen好貸</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video19"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(19)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic19.png" alt="Contact Image"/>
                        <p>台灣通用紡織科技 Frontier</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video20"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(20)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic20.png" alt="Contact Image"/>
                        <p>雅匠科技</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video21"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(21)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/2/pic21.png" alt="Contact Image"/>
                        <p>肆柒影像工作室 JSRTEX</p>
                        <div class="Circle"/>
                    </div>
                </div>
                <div class="WindowVideo" v-if="isVideo" @click="OffVideo()"
                    @keydown="handleKeyDown">
                    <div class="WinVideo1">
                        <div v-for="(video, index) in filteredWindowVideos"
                            :key="index" class="window{{ index + 1 }}">
                            <iframe :src="video" title="Descriptive Title"/>
                        </div>
                    </div>
                </div>
            <div class="DownBackGround"/>
        </div>
</template>

<script>

export default {
    data() {
        return {
            hoveredIndex: null,
            isVideo: false,
            selectedVideoIndex: 0,
            Videos: [
                'https://www.youtube.com/embed/bMM2UX0rsqA?si=iObSV7qe6E-9FMLt&autoplay=1&mute=1',
                'https://www.youtube.com/embed/Dt-OhvMtWEc?si=00Db3z4HtcM6en5Q&autoplay=1&mute=1',
                'https://www.youtube.com/embed/Lqoe4_v-RLo?si=KxibYCtb5quI3i9h&autoplay=1&mute=1',
                'https://www.youtube.com/embed/LGPdF2-NpI4?si=ENOyUUDuAn_hoN2K&autoplay=1&mute=1',
                'https://www.youtube.com/embed/22eqQjQvRTw?si=uY-V-YtDMZG5vIMV&autoplay=1&mute=1',
                'https://www.youtube.com/embed/pf7VRrT2Py4?si=bx1Te6aFXBefj5_7&autoplay=1&mute=1',
                'https://www.youtube.com/embed/rB507qeesgs?si=T2uk3qVwcZPJZJaR&autoplay=1&mute=1',
                'https://www.youtube.com/embed/xJTjn_pFD6A?si=-zH_CXe8CbQGB5bz&autoplay=1&mute=1',
                'https://www.youtube.com/embed/1B2bMQbe6sk?si=XYreI6u4rm4I-8X5&autoplay=1&mute=1',
                'https://www.youtube.com/embed/7GJDDDo7mzo?si=OsrdmaFksdifK1LA&autoplay=1&mute=1',
                'https://www.youtube.com/embed/KWLv1_rsG0Y?si=1W5we9SrwK1E2Ndq&autoplay=1&mute=1',
                'https://www.youtube.com/embed/Ee6S6iIycrE?si=1tVUKu0tnAhWqPWS&autoplay=1&mute=1',
                'https://www.youtube.com/embed/RLoDutWDwIE?si=3BZlB4CcpldRIaLQ&autoplay=1&mute=1',
                'https://www.youtube.com/embed/dmdTMcIo4JQ?si=DwHM8bkP9Wntb0kZ&autoplay=1&mute=1',
                'https://www.youtube.com/embed/b-OsKAUOuM0?si=b45Uxa4rLkZQpXAP&autoplay=1&mute=1',
                'https://www.youtube.com/embed/a8JivcdrR5w?si=DAZVii0BhA-BV5pL&autoplay=1&mute=1',
                'https://www.youtube.com/embed/gb7iuAYOScA?si=yyVa9dgM4RMcjPzE&autoplay=1&mute=1',
                'https://www.youtube.com/embed/hbJUkxZSfCk?si=9_OncYa6m2qdOzNl&autoplay=1&mute=1',
                'https://www.youtube.com/embed/kK0K0zDJRmM?si=XpmPFF4Hkxs7GqOR&autoplay=1&mute=1',
                'https://www.youtube.com/embed/v_3uSW4Aknk?si=lDM90IEdpyYh92zc&autoplay=1&mute=1',
                'https://www.youtube.com/embed/JmGjVhRWS2M?si=7RtVfVfLAPzBUmp1&autoplay=1&mute=1',
            ],
        };
    },
    computed: {
        filteredWindowVideos() {
            return this.Videos.filter((_, index) => index === this.selectedVideoIndex);
        },
    },
    methods: {
        togglePlay() {
            const video = document.getElementById('12');
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        },
        openVideo(index) {
            this.isVideo = true;
            this.selectedVideoIndex = index - 1;
        },
        OffVideo() {
            this.isVideo = false;
        },
        handleMouseEnter(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'block';
        },
        handleMouseLeave(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'none';
        },
    },
};
</script>

<style>
.WorksCard2 {
    background-size: 100%;
    background-image: url('/public/data/works/BackGround2.png');
}
.WorksCard2 .title{
    position: absolute;
    padding: 2vw 0 0 10vw;
    color: #F08437;
    font-size: 3vw;
    font-weight: bolder;
}
.WorksCard2 .contents{
    position: absolute;
    padding: 7vw 0 0 10vw;
    font-size: 3vw;
    color: #C0AB9C;
}

@media (max-width: 900px) {
    .WorksCard2 {
        background-image: none;
    }
}
</style>
