<template>
    <div class="hometab tittlecard">
        <div class="overlay"/>
        <video class="background-video" autoplay muted loop playsinline>
            <source src="../data/video/Home.mp4" type="video/mp4">
        </video>
        <img src="../data/Home/Title.png" alt="Contact Image" >
        <div class="overlay2"/>
        <div class="overlay3"/>
    </div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.2/vanilla-tilt.min.js';
        script.async = true;
        document.body.appendChild(script);
    },
};
</script>

<style>
.hometab {
    position: relative;
    padding: 0% 0% 0%;
}
.hometab .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130%;
  background: linear-gradient(rgba(0, 0, 0, 0.2)0%,
            rgba(0, 0, 0, 0.2)70%, rgba(255, 255, 255, 0)100%);
  z-index: 1;
}
.hometab .overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background: linear-gradient(#242b4600 0%,#232b4800 55%,#1a1f3266 80%,
            #1A1F32 90%,#1a1f3200 100%);
  z-index: 2;
}
.hometab .overlay3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 105%;
  background: linear-gradient(#000000 0%,
            #0000008b 80%,#242b4600 100%);
  z-index:-5;
}
.hometab img{
    position: absolute;
    top: 35vw;
    left: 5vw;
    z-index: 10;
}

.background-video {
    top: 0; left: 0;
    width: 100%;
    height: 90%;
}
.tittlecard .Companylogo {
    max-height: 100%;
    max-width: 100%;
}
@media (max-width: 900px) {
    .hometab {
        padding: 12% 0% 0%;
        position: relative;
    }
    .hometab img {
        left: 0vw;
        width: 90vw;
        margin: 105vw 0 0 0;
    }
    .background-video {
        position: relative;
        width: 300vw;
        left: -100vw;
    }
    .hometab .overlay {
        height: 108%;
    }
}
</style>
