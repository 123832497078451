<template>
    <div class="WorksView">
        <WorksTab/>
          <WorksContent/>
        <ContactContent/>
    </div>
</template>

<script>
import WorksTab from '@/components/WorksTab.vue';
import WorksContent from '@/components/WorksContent.vue';
import ContactContent from '@/components/ContactContent.vue';

export default {
  name: 'WorksView',
  components: {
    WorksTab,
    WorksContent,
    ContactContent,
  },
};
</script>

<style>
.WorksView {
  cursor: url('https://www.flaticon.com/free-icon/new-moon_686700?term=circle&related_id=686700');
  overflow-x: hidden;
  background-image: url('../data/BackGround/HomeBackGround.png');
  background-size: 100% auto;
}
@media (max-width: 900px) {
  .WorksView {
    background-image: url('../data/BackGround/Phone/phone_works.png');
  }
}
</style>
