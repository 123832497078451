<template>
        <div class="WorksCard5">
            <p class="title">人物專訪</p>
            <p class="contents">真情真摯, 一窺生命背後精采篇章</p>
                <div class="Card">
                    <div class="video" id="none">
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video1"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(1)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/5/pic1.png" alt="Contact Image"/>
                        <p>今周刊30 | 資訊及數位</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video2"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(2)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/5/pic2.png" alt="Contact Image"/>
                        <p>今周刊30 | 精準醫療</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video3"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(3)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/5/pic3.png" alt="Contact Image"/>
                        <p>今周刊30 | 耐能智慧股份有限公司</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video4"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(4)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/5/pic4.png" alt="Contact Image"/>
                        <p>香尼歐</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video5"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(5)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/5/pic5.png" alt="Contact Image"/>
                        <p>TIRI | 採訪片段</p>
                        <div class="Circle"/>
                    </div>
                </div>
                <div class="WindowVideo" v-if="isVideo" @click="OffVideo()"
                    @keydown="handleKeyDown">
                    <div class="WinVideo1">
                        <div v-for="(video, index) in filteredWindowVideos"
                            :key="index" class="window{{ index + 1 }}">
                            <iframe :src="video" title="Descriptive Title"/>
                        </div>
                    </div>
                </div>
            <div class="DownBackGround"/>
        </div>
</template>

<script>

export default {
    data() {
        return {
            hoveredIndex: null,
            isVideo: false,
            selectedVideoIndex: 0,
            Videos: [
                'https://www.youtube.com/embed/QDFfNeXyxfQ?si=gnRMuMUv6eMYLmMi&autoplay=1&mute=1',
                'https://www.youtube.com/embed/khXfWcg-kPw?si=unYT5Za4_FyRCUVx&autoplay=1&mute=1',
                'https://www.youtube.com/embed/pd5KRXRvo0s?si=E3pDK-uGByhuguSl&autoplay=1&mute=1',
                'https://www.youtube.com/embed/EIPqHItCYG8?si=94oTtDh_ZY7ePb6z&autoplay=1&mute=1',
                'https://www.youtube.com/embed/cpBIxBO_FO0?si=86LqCU-I-M1gaiWV&autoplay=1&mute=1',
            ],
        };
    },
    computed: {
        filteredWindowVideos() {
            return this.Videos.filter((_, index) => index === this.selectedVideoIndex);
        },
    },
    methods: {
        togglePlay() {
            const video = document.getElementById('12');
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        },
        openVideo(index) {
            this.isVideo = true;
            this.selectedVideoIndex = index - 1;
        },
        OffVideo() {
            this.isVideo = false;
        },
        handleMouseEnter(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'block';
        },
        handleMouseLeave(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'none';
        },
    },
};
</script>

<style>
.WorksCard5 {
    background-size: 100%;
    background-image: url('/public/data/works/BackGround5.png');
}
.WorksCard5 .title{
    position: absolute;
    padding: 2vw 0 0 10vw;
    color: #F08437;
    font-size: 3vw;
    font-weight: bolder;
}
.WorksCard5 .contents{
    position: absolute;
    padding: 7vw 0 0 10vw;
    font-size: 3vw;
    color: #C0AB9C;
}

@media (max-width: 900px) {
    .WorksCard5 {
        background-image: none;
    }
}
</style>
