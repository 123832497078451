<template>
        <div class="WorksCard4">
            <p class="title">活動紀錄</p>
            <p class="contents">歡笑回顧, 與品牌共創美好時刻</p>
                <div class="Card">
                    <div class="video" id="none">
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video1"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(1)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic1.png" alt="Contact Image"/>
                        <p>搬鼓人 | 滿山望海</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video" id="none">
                    </div>
                    <div class="video2"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(2)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic2.png" alt="Contact Image"/>
                        <p>威京</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video3"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(3)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic3.png" alt="Contact Image"/>
                        <p>2021展覽國際大師營</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video4"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(4)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic4.png" alt="Contact Image"/>
                        <p>搬鼓人 | 朗居文旅</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video5"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(5)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic5.png" alt="Contact Image"/>
                        <p>搬鼓人 | 頭城農場</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video6"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(6)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic6.png" alt="Contact Image"/>
                        <p>花蓮有機農業論壇</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video7"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(7)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic7.png" alt="Contact Image"/>
                        <p>精彩創意x伊雲谷 | 上市上櫃</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video8"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(8)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic8.png" alt="Contact Image"/>
                        <p>今周刊講座</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video9"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(9)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic9.png" alt="Contact Image"/>
                        <p>Dazzling | 一鏡到底</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video10"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(10)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic10.png" alt="Contact Image"/>
                        <p>宅配通 | 20周年</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video11"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(11)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic11.png" alt="Contact Image"/>
                        <p>M3 Tek | 上市典禮</p>
                        <div class="Circle"/>
                    </div>
                    <div class="video12"
                        @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave"
                        @focusin="handleMouseLeave"
                        @focusout="handleMouseLeave"
                        @click="openVideo(12)"
                        @keydown="handleKeyDown">
                        <img class="video-img" src="data/works/4/pic12.png" alt="Contact Image"/>
                        <p>亞太電信 | Demo Day</p>
                        <div class="Circle"/>
                    </div>
                </div>
                <div class="WindowVideo" v-if="isVideo" @click="OffVideo()"
                    @keydown="handleKeyDown">
                    <div class="WinVideo1">
                        <div v-for="(video, index) in filteredWindowVideos"
                            :key="index" class="window{{ index + 1 }}">
                            <iframe :src="video" title="Descriptive Title"/>
                        </div>
                    </div>
                </div>
            <div class="DownBackGround"/>
        </div>
</template>

<script>

export default {
    data() {
        return {
            hoveredIndex: null,
            isVideo: false,
            selectedVideoIndex: 0,
            Videos: [
                'https://www.youtube.com/embed/8iZSBd2l3XU?si=pe5oWUHlu2f2qAqx&autoplay=1&mute=1',
                'https://www.youtube.com/embed/FRsdazzX4lE?si=7mEvkD4_4ULxs6Sq&autoplay=1&mute=1',
                'https://www.youtube.com/embed/frjQb42pbso?si=TE2aLQghnRANJQZ2&autoplay=1&mute=1',
                'https://www.youtube.com/embed/kI3JeYFg77c?si=KEJdrjoZ-NTW8BLL&autoplay=1&mute=1',
                'https://www.youtube.com/embed/_jMIgLtXHG4?si=zD3EljW0UdB_wIw_&autoplay=1&mute=1',
                'https://www.youtube.com/embed/MCPrIzp8G-k?si=dzPSlaB-MPyx-HcW&autoplay=1&mute=1',
                'https://www.youtube.com/embed/j_VpNk_sJiM?si=Hny8VGAdVagZNg2h&autoplay=1&mute=1',
                'https://www.youtube.com/embed/B9CpXZRhzLU?si=FtZ8qbJQfs4vHvUE&autoplay=1&mute=1',
                'https://www.youtube.com/embed/Egv1UjUZe2M?si=GQYYHFIvbixnPA9s&autoplay=1&mute=1',
                'https://www.youtube.com/embed/S-mEe-vOya8?si=c4HJpklmJdb516-s&autoplay=1&mute=1',
                'https://www.youtube.com/embed/az36LfHG09g?si=at20c4BsOUCSgECJ&autoplay=1&mute=1',
                'https://www.youtube.com/embed/pbYHcRO5BYk?si=7rOvNhgPwNV-KA2_&autoplay=1&mute=1',
            ],
        };
    },
    computed: {
        filteredWindowVideos() {
            return this.Videos.filter((_, index) => index === this.selectedVideoIndex);
        },
    },
    methods: {
        togglePlay() {
            const video = document.getElementById('12');
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        },
        openVideo(index) {
            this.isVideo = true;
            this.selectedVideoIndex = index - 1;
        },
        OffVideo() {
            this.isVideo = false;
        },
        handleMouseEnter(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'block';
        },
        handleMouseLeave(event) {
            const target = event.currentTarget;
            const circle = target.querySelector('.Circle');
            circle.style.display = 'none';
        },
    },
};
</script>

<style>
.WorksCard4 {
    background-size: 100%;
    background-image: url('/public/data/works/BackGround4.png');
}
.WorksCard4 .title{
    position: absolute;
    padding: 2vw 0 0 10vw;
    color: #F08437;
    font-size: 3vw;
    font-weight: bolder;
}
.WorksCard4 .contents{
    position: absolute;
    padding: 7vw 0 0 10vw;
    font-size: 3vw;
    color: #C0AB9C;
}

@media (max-width: 900px) {
    .WorksCard4 {
        background-image: none;
    }
}
</style>
