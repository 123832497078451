<template>
    <footer class="footer">
        <div class="navbar">
            <div class="sub-tab">
                <div class="Text1">
                    <img class="Logo" src="data/BackGround/Logo2.png" alt=""/>
                    <div class="text">
                        <p>透過多年累積的經驗，<br>我們不僅擁有大量海內外的優質作品，<br>更不斷創新應用新的拍攝剪輯手法。</p>
                        <p>以獨特手法呈現有溫度的故事，<br>讓您的品牌在市場脫穎而出，<br>贏得目標受眾的心！</p></div>
                    </div>
                <div class="Text2">
                    <div class="Person">
                        <img src="../data/Footer/Person.png" class="icon" alt=" ">
                        <a id="Company"> 榮恩 Ron</a>
                    </div>
                    <div class="Phone">
                        <img src="../data/Footer/Phone.png" class="icon" alt=" ">
                        <a id="Phone" :href="'tel:' + phoneNumber"
                            onclick="return gtag_report_call()">0921-910-131 </a>
                        <a class="l" id="iPhone"> | </a>
                        <a id="Phone" :href="'tel:' + phoneNumber2"
                            onclick="return gtag_report_call()">02-2873-2050 </a>
                    </div>
                    <div class="Email">
                        <img src="../data/Footer/Email.png" class="icon" alt=" ">
                        <a id="Gmail" :href="'mailto:' + emailAddress">ronproductiontw@gmail.com</a>
                    </div>
                    <a class="l"> | </a>

                    <a class="Countent" href="https://usstudiofilm.com/wp-content/uploads/2019/08/%E6%9C%89%E6%BA%AB%E5%BA%A6%E6%94%9D%E5%BD%B1%E5%B7%A5%E4%BD%9C%E5%AE%A4%EF%BD%9C%E5%A0%B1%E5%83%B9%E5%96%AE_0822.pdf" target="_blank">
                        <img src="../data/Footer/content.png" alt=" ">
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.youtube.com/@rogershen81411" target="_blank">
                        <img src="../data/Footer/Youtube-P.png" alt=" ">
                    </a>
                    <a href="https://line.me/R/ti/p/@mjz1813g"
                    onclick="return gtag_report_line()"
                    target="_blank">
                        <img src="../data/Footer/Line-P.png" alt=" ">
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    data() {
        return {
            emailAddress: 'ronproductiontw@gmail.com',
            phoneNumber: '88621910131',
            phoneNumber2: '02-2873 - 2050',
        };
    },
};
</script>

<style>
.footer {
    background-color: #384162;
    height: 30vw; width: 100%;
    color: rgba(212, 218, 218, 1);
    font-weight: 40vw;
    position: relative;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.sub-tab{
    height: 100%;
}
.footer .sub-tab > div {
    font-size: 1vw;
    font-weight: bold;
    margin-right: 1.75vw;
}

.footer a {
    text-decoration: none;
    font-size: 0.9vw;
    color: #9EADFA;
}
.footer .Text1{
    position: relative;
    top: 8vw;
    color: #FFDFC8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .Text1 p{
    position: relative;
    top: 0.5vw;
    left: 2vw;
    line-height: 2vw;
    text-align: left;
    font-weight: normal;
}
.footer .Text1 br {
    display: none;
}
.footer .Text2 {
    position: relative;
    top: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .Text2 a {
    font-size: 1.5vw;
    margin-right: 1vw;
    text-decoration: none;
}
.footer .Text2 .l {
    position: relative;
    left: -0.3vw;
    margin-right: 0.5vw;
}
.footer .Text2 img {
    position: relative;
    top: 0.5vw;
    width: 2.5vw;
    padding: 0 0.5vw 0 0;
}
.footer .Countent{
    position: relative;
    width: 10vw;
}
.footer .Countent img {
    width: 100%;
}
.footer .link {
    position: relative;
    top: 13vw;
}
.footer .link img{
    width: 4vw;
    margin-right: 1.5vw;
}
.footer .Logo{
    position: relative;
    width: 15vw;
}
  @media (max-width: 900px) {
    .footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 93vh;
    }
    .footer .Logo {
        position: relative;
        top: -17vw;
        left: -20vw;
        width: 35vw;
        height: 11.5vw;
    }
    .footer .sub-tab > div {
        margin-right: 0vw;
    }
    .footer .Text1 {
        display: block;
        top: 25%;
        width: 100vw;
        height: 50vw;
        padding: 0 10vw;
    }
    .footer .Text1 p {
        left: 0vw;
        font-size: 4.5vw;
        line-height: 8vw;
    }
    .footer .Text1 br {
        display: block;
    }
    .footer .Text2 {
        position: relative;
        display: block;
        text-align: left;
        width: 100vw;
        height: 25vw;
        top: 34%;
        padding: 0 8vw;
    }

    .footer .Text2 a {
        position: relative;
        font-size: 4.5vw;
        display: block;
        margin: 1vw;
    }
    .footer .Text2 .l {
        display: none;
    }
    .footer .Text2 img {
        width: 8vw;
        padding: 0 0.5vw 0 0;
    }
    .footer .Text2 .Countent {
        position: relative;
        top: -88vw;
        left: 48vw;
        width: 36vw;
    }
    .footer .Text2 .Countent img{
        top: -27vw;
        width: 100%;
    }
    .footer .Text2 >div {
        display: flex;
        align-items: center;
        margin: 2vw;
    }
    .footer .Text2 #iPhone {
        top: -0.5vw;
        display: block;
        margin: 0 1vw 1vw 1vw;
    }
    .footer .link {
        top: 39%;
        text-align: left;
        padding: 0 8vw;
    }
    .footer .link img {
        position: relative;
        width: 15vw;
        margin: 0 3vw;
    }
}
</style>
