<template>
    <div class="project ContactCon">
      <!-- <script src="https://unpkg.com/sweetalert/dist/sweetalert.min.js"></script> -->
        <div class="tittle">
            <p class="text1" id="ContactUs">攝影諮詢</p>
            <p class="text2">留下需求 ,  我們將儘速與你聯繫！</p>
        </div>
          <div>
          <div>
            <form ref="form" @submit.prevent="sendEmail" class="custom-form">
              <div class="Text">
                <div class="form-container">
                  <div class="form-group align-left">

                    <label class="name" for="name">
                      <p class="tittle">您的姓名<span class="red-star">*</span><br></p>
                      <input type="text" id="name" name="from_name" class="inputCont"
                         placeholder="請輸入您的中/英文姓名" v-model="from_name" required>
                    </label>

                    <label class="company" for="company">
                      <p class="tittle">公司</p>
                      <input type="text" id="company" name="from_Com" class="inputCont"
                        placeholder="請輸入公司名稱" v-model="from_Com">
                    </label>

                    <label class="email" for="email">
                      <p class="tittle">電子信箱<span class="red-star">*</span><br></p>
                        <input type="email" id="email"  name="from_email" class="inputCont"
                            placeholder="請輸入您的電子信箱" v-model="from_email" required>
                    </label>

                    <label class="number" for="number">
                      <p class="tittle">聯絡電話<span class="red-star">*</span><br></p>
                      <div class="LRNum">
                        <div class="rightNum">
                          <input type="text"  name="from_call" class="inputCont" id="call2"
                                placeholder="請輸入您的電話" v-model="from_call" required>
                        </div>
                      </div>
                    </label>
                    <label class="budget" for="budget">
                      <p class="tittle">預算規劃<span class="red-star">*</span><br></p>
                      <div class="LRNum">
                        <div class="rightNum">
                          <input type="text"  name="from_budget" class="inputCont" id="budget"
                                placeholder="請輸入您的預算" v-model="from_budget" required>
                        </div>
                      </div>
                    </label>

                    <!-- <div class="Slider">
                      <div>
                      <p class="tittle">預算規劃<span class="red-star">*</span><br></p>
                        <div class="checkbox-group">
                          <label class="CheckBox01" for="vehicle1">
                            <input type="checkbox" id="vehicle1"
                                  name="CheckBox01"
                                  v-model="budgetCheckboxes.CheckBox01"
                                  v-on:change="handleBudgetCheckboxChange('CheckBox01')">
                            <span>5萬以下</span>
                          </label>
                          <label class="CheckBox02" for="vehicle2">
                            <input type="checkbox" id="vehicle2"
                                  name="CheckBox02"
                                    v-model="budgetCheckboxes.CheckBox02"
                                    v-on:change="handleBudgetCheckboxChange('CheckBox02')">
                            <span>5萬~10萬</span>
                          </label>
                          <label class="CheckBox03" for="vehicle3">
                            <input type="checkbox" id="vehicle3"
                                  name="CheckBox03"
                                    v-model="budgetCheckboxes.CheckBox03"
                                    v-on:change="handleBudgetCheckboxChange('CheckBox03')">
                            <span>10萬~20萬</span>
                          </label>
                          <label class="CheckBox04" for="vehicle4">
                            <input type="checkbox" id="vehicle4"
                                  name="CheckBox04"
                                    v-model="budgetCheckboxes.CheckBox04"
                                    v-on:change="handleBudgetCheckboxChange('CheckBox04')">
                            <span>20萬~30萬</span>
                          </label>
                          <label class="CheckBox05" for="vehicle5">
                            <input type="checkbox" id="vehicle5"
                                  name="CheckBox05"
                                    v-model="budgetCheckboxes.CheckBox05"
                                    v-on:change="handleBudgetCheckboxChange('CheckBox05')">
                            <span>30萬~50萬</span>
                          </label>
                          <label class="CheckBox06" for="vehicle6">
                            <input type="checkbox" id="vehicle6"
                                  name="CheckBox06"
                                    v-model="budgetCheckboxes.CheckBox06"
                                    v-on:change="handleBudgetCheckboxChange('CheckBox06')">
                            <span>50萬以上</span>
                          </label>
                        </div>
                      </div>
                    </div> -->
                  </div>

                  <div class="form-group align-right">
                    <div class="CheckBox">
                      <div class="rightBoxDemand">
                        影片需求
                        <span class="red-star">*</span>
                      </div>

                      <div class="checkbox-group2">
                        <label class="CheckBox01" for="vehicle11">
                          <input type="checkbox" id="vehicle11"
                                name="CheckBox11" >
                          <span>品牌形象影片</span>
                        </label>
                        <label class="CheckBox02" for="vehicle12">
                          <input type="checkbox" id="vehicle12"
                                name="CheckBox12" >
                          <span>企業簡介影片</span>
                        </label>
                        <label class="CheckBox03" for="vehicle13">
                          <input type="checkbox" id="vehicle13"
                                name="CheckBox13">
                          <span>人物訪談</span>
                        </label>
                        <label class="CheckBox04" for="vehicle14">
                          <input type="checkbox" id="vehicle14"
                                name="CheckBox14">
                          <span>產品宣傳短片</span>
                        </label>
                        <label class="CheckBox05" for="vehicle15">
                          <input type="checkbox" id="vehicle15"
                                name="CheckBox15" >
                          <span>IPO上市櫃影片</span>
                        </label>
                        <label class="CheckBox06" for="vehicle16">
                          <input type="checkbox" id="vehicle16"
                                name="CheckBox16">
                          <span>微電影</span>
                        </label>
                        <label class="CheckBox07" for="vehicle17">
                          <input type="checkbox" id="vehicle17"
                                name="CheckBox17">
                          <span>網路廣告/TVC</span>
                        </label>
                        <label class="CheckBox08" for="vehicle18">
                          <input type="checkbox" id="vehicle18"
                                name="CheckBox18">
                          <span>活動紀錄</span>
                        </label>
                        <label class="CheckBox09" for="vehicle19">
                          <input type="checkbox" id="vehicle19"
                                name="CheckBox19">
                          <span>其他</span>
                        </label>
                        <div class="ContText">
                            <textarea type="text" id="reqText" name="from_ContText1"
                            placeholder="(請簡述需求內容幫助我們對製作案有初步認識)"
                            v-model="from_ContText1"></textarea>
                        </div>

                      </div>
                    </div>

                        <div class="rightBoxDemand2">參考影片
                          <span class="red-star">*</span>
                          <div class="ContText">
                          <label class="CheckBox09" for="vehicle9">
                              <textarea type="text" id="reqText" name="from_ContText2"
                              placeholder="(如有預期風格、質感表現參考影片等，可在此提供連結)" required
                              v-model="from_ContText2"></textarea>
                          </label>
                          </div>
                        </div>
                  <button type="submit" id="Send" value="Send"
                          onclick="return gtag_report_send()">
                    <!--  onClick="fbq('track', 'Lead');" -->
                    送出</button>
                  </div>
              </div>
              </div>
            </form>
          </div>
        </div>
  <div class="FormBack" id="formBack">
    <div class="formCard">
      <button class="closeButton"  @click="OFFFormBack()" id="closeForm">
        <img src="https://usstudiofilm.com/data/icon_close.png" alt=" "></button>
      <img src="https://usstudiofilm.com/data/OKicon.png" alt=" ">
      <p>表單已送出 !</p>
      <p>專人將於 1-3 個工作天內聯絡您</p>
      <button @click="OFFFormBack()">我知道了</button>
    </div>
  </div>

    </div>
</template>

<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11266871809"></script> -->
<script>
import { mapMutations } from 'vuex';
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      budgetCheckboxes: {
        CheckBox01: false,
        CheckBox02: false,
        CheckBox03: false,
        CheckBox04: false,
        CheckBox05: false,
        CheckBox06: false,
      },
      selectedOptions: '',
    };
  },
  watch: {
    rangeMax() {
      if (this.rangeMax - this.rangeMin < 2) {
         this.rangeMax = parseInt(this.rangeMin, 10) + 1;
      }
    },
    rangeMin() {
      if (this.rangeMax - this.rangeMin < 2) {
        this.rangeMin = parseInt(this.rangeMax, 10) - 1;
      }
    },
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js';
    // script.src = 'https://www.npmjs.com/package/emailjs-com';
    fetch('json/projectTab.json')
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to fetch data.json');
      })
      .then((data) => {
        this.classifications = data;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  },
  computed: {
    currentStep() {
      const currentValue = parseInt(this.sliderValue, 10);
      if (this.sliderValue >= this.maxValue) {
        return `${this.nums[currentValue].toLocaleString()}+ `;
      }
      return `${this.nums[currentValue].toLocaleString()} `;
    },
    ProgressStyle() {
      return {
          left: `${this.rangeMin * 9}%`,
          right: `${100 - this.rangeMax * 9}%`,
        };
    },
  },
  methods: {
    sendEmail(event) {
      event.preventDefault();
      const referenceVideoCheckboxes = [];
      document.querySelectorAll('.checkbox-group2 input[type="checkbox"]').forEach((checkbox) => {
        if (checkbox.checked) {
          referenceVideoCheckboxes.push(checkbox.nextElementSibling.textContent);
        }
      });
      this.getSelectedOptions();
      const serviceID = 'service_py2zj7r';
      const templateID = 'template_qmozinm';
      const userID = 'QdHWY3k7nDNmNb-CS';
      const formSele = '.custom-form';
      const formData = {
        from_name: this.from_name,
        from_Com: this.from_Com,
        from_email: this.from_email,
        from_call: this.from_call,
        from_ContText1: this.from_ContText1,
        from_ContText2: this.from_ContText2,
        from_budget: this.from_budget,
        referenceVideoCheckboxes: referenceVideoCheckboxes.join(', '),
      };

      console.log('formData', formData);
      emailjs.send(serviceID, templateID, formData, userID)
       .then(() => {
         console.log(this.numK[this.rangeMin], '～', this.numK[this.rangeMax]);
         console.log('Sent');
       })
       .catch((err) => {
         console.log(JSON.stringify(err));
         this.showFormBack();
       });
    //   emailjs.sendForm(serviceID, templateID, formSele, userID)
    // .then(() => {
    //   console.log(this.nmuk[this.rangeMin], '~', this.numk[this.rangeMax]);
    //   alert('Sent');
    // })
    // .catch((err) => {
    //   console.log(JSON.stringify(err));
    //   alert('Sent');
    // });
  },
    showFormBack() {
      // 显示 .FormBack 的逻辑
      const formBackElement = document.getElementById('formBack');
      if (formBackElement) {
        formBackElement.style.display = 'block';
      }
    },
    OFFFormBack() {
      // 显示 .FormBack 的逻辑
      const formBackElement = document.getElementById('formBack');
      if (formBackElement) {
        formBackElement.style.display = 'none';
      }
    },
    handleBudgetCheckboxChange(checkboxName) {
      Object.keys(this.budgetCheckboxes).forEach((key) => {
        if (key !== checkboxName) {
          this.budgetCheckboxes[key] = false;
        }
      });
    },
    getSelectedOptions() {
      this.selectedOptions = '';
      Object.entries(this.budgetCheckboxes).forEach(([key, value]) => {
        if (value) {
          const label = this.getLabelByKey(key);
          this.selectedOptions += label;
        }
      });
    },
    getLabelByKey(key) {
      // 根据键值返回对应的标签内容
      switch (key) {
        case 'CheckBox01':
          return '5萬以下';
        case 'CheckBox02':
          return '5萬~10萬';
        case 'CheckBox03':
          return '10萬~20萬';
        case 'CheckBox04':
          return '20萬~30萬';
        case 'CheckBox05':
          return '30萬~50萬';
        case 'CheckBox06':
          return '50萬以上';
        default:
          return '';
      }
    },

  },
};
</script>

<style>
#height_slider .e-handle,
#gradient_slider .e-handle {
  height: 20px;
  width: 20px;
  margin-left: -10px;
  top: calc(50% - 10px);
}
  .ContactCon {
    position: relative;
    height: 70vw;
    padding: 0 18vw 0 18vw;
  }

  .ContactCon .text1 {
    color: #FFB37F;
    font-size: 3.5vw;
    font-weight: bold;
    text-align: center;
    margin: 0 0 1vw 0;
  }
  .ContactCon .text2 {
    color: #C0AB9C;
    font-size: 1.2vw;
    font-weight: bold;
    text-align: center;
    margin: 0 0 3vw 0;
  }
  .ContactCon .form {
    font-family: 'Courier New', Courier, monospace;
  }

  .ContactCon .custom-form {
    max-width: 100%;
    padding: 0% 0% 5%;
    text-align: left;
    flex-wrap: wrap;
  }

  .ContactCon .Text {
    display: flex;
    justify-content: space-between;
  }

  .ContactCon .Text .form-container{
    display: flex;
    width: 100%;
  }

  .ContactCon .align-left {
    width: 50%;
    z-index: 1;
  }

  .ContactCon .align-right {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .ContactCon .checkbox-group, .checkbox-group2{
    display: grid;
    width: 30vw;
    font-weight:normal;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    z-index: 5;
  }
  .ContactCon .form-group.align-left .checkbox-group label,
              .form-group.align-right .checkbox-group label{
    position: relative;
    top: 1vw;
    height: 2.6vw;
  }
  .ContactCon .form-group.align-left .checkbox-group label,.checkbox-group2 label{
    text-align: left;
  }
  .ContactCon .form-group.align-left .tittle ,.Slider p{
    position: relative;
    font-weight: bolder;
    position: relative;
    display: block;
    font-size: 1.25vw;
    padding: 15px 1px 3px 1px;
    color: #FFDFC8;
  }
  .ContactCon .Text .inputCont {
    width: 30vw;
    height: 3.5vw;
    font-size: 1.1vw;
    padding: 0.5vw 0vw 0.5vw 0.8vw;
    margin: 0.5vw 0vw 0vw 0vw;
    color: #C0AB9C;
    background-color: #00000000;
    border: 2.5px solid #C0AB9C;
    border-radius: 0.5vw;
  }

  .form-container .red-star {
    color: red;
  }
  .ContactCon .align-left .number .LRNum {
    align-items: center;
  }

  .ContactCon .align-left .number .LRNum .leftNum .inputCont{
    width: 10vw;
    margin-right: 1vw;
  }

  .ContactCon form button {
    position: relative;
    top: 22vw;
    transform: translate(-50%, -50%);
    height: 3vw; width: 8vw;
    font-size: 1vw; font-weight: bold;
    color: #384162;
    background-color: #FFB37F;
    border: none;
    border-radius: 1vw;
    z-index: 1;
  }

  .ContactCon form button:hover {
    font-weight:bold;
    box-shadow: 0 0 10px #FFB37F;
  }

  .ContactCon #address option {
      max-height: 100px;
      overflow: hidden;
      white-space: nowrap;
  }

  .ContactCon .align-right .CheckBox {
    color: #C0AB9C;
    width: 37vw;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }

  .ContactCon .align-right .rightBoxDemand, .rightBoxDemand2{
    position: relative;
    font-size: 1.25vw;
    width: 46vw;
    padding: 15px 1px 3px 1px;
    color: #FFDFC8;
    display: block;
    font-weight: bolder;
    z-index: 1;
  }
  .ContactCon .align-right .rightBoxDemand2{
    top: 4vw;
  }
  .ContactCon .align-right .rightBoxDemand2 .ContText{
    position: relative;
    top: 0.5vw;
  }
  .ContactCon .align-right .CheckBox .checkbox-group span:hover,
   #vehicle1:hover, #vehicle2:hover, #vehicle3:hover, #vehicle4:hover, #vehicle5:hover,
   #vehicle6:hover, #vehicle7:hover, #vehicle8:hover, #vehicle9:hover
  .ContactCon .align-right .CheckBox .checkbox-group2 span:hover,
   #vehicle11:hover, #vehicle12:hover, #vehicle13:hover, #vehicle14:hover, #vehicle15:hover,
   #vehicle16:hover, #vehicle17:hover, #vehicle18:hover, #vehicle19:hover{
    cursor: pointer;
  }

  .ContactCon .align-right .CheckBox .ContText{
    position: relative;
    grid-column: span 3;
    height: 5.25vw; width: 30.5vw;
    color: #C0AB9C;
    border-radius: 0.5vw;
    top: 1.1vw; left: 0.5vw;
  }

  .ContactCon .Text input{
    border: none; outline: none;
    padding: 0.5vw 0vw 2vw 0.5vw;
    width: 100%; height: 100%;
    background-color: #111110;
    border: 0.1vw solid #C0AB9C;
    border-radius: 0.5vw;
    text-align: left;
    word-wrap: break-word;
    left: 0.5vw;
  }

  .ContactCon .Text input:valid, .ContactCon #address:valid,
   .ContactCon #email:valid .ContactCon .Text .inputCont:valid{
    color: #C0AB9C;
  }
  #vehicle1,#vehicle2,#vehicle3, #vehicle4,#vehicle5,#vehicle6, #vehicle7,#vehicle8,#vehicle9,
  #vehicle11,#vehicle12,#vehicle13, #vehicle14,#vehicle15,#vehicle16 ,
  #vehicle17,#vehicle18,#vehicle19{
    position: relative;
    font-weight: bold;
    margin-right: 7px;
    top: 0.1vw;
    width: 1.3vw;
    height: 1.3vw;
  }
.slider label {
  font-size: 24px;
  font-weight: 400;
  font-family: Open Sans;
  padding-left: 10px;
  color: black;
}
.slider input[type="range"] {
  width: 420px;
  height: 2px;
  background: black;
  border: none;
  outline: none;
}

  .Slider .tittle {
    font-size: 1.15vw;
    color: #FFDFC8;
    z-index: 1;
  }

  .checkbox-group input[type="checkbox"], .checkbox-group2 input[type="checkbox"]{
    padding: 0;
    -webkit-appearance: none;
    background-color: #00000000;
    border-radius: 50%;
  }
  .checkbox-group input[type="checkbox"]:checked,  .checkbox-group2 input[type="checkbox"]:checked{
    background-color: #C0AB9C;
    color: white;
    border: #000000;
  }
  .align-right #reqText {
        position: relative;
        height: 8vw;
        width: 31vw;
        font-size: 1vw;
        color: #C0AB9C ;
        margin-top: 2px;
        padding: 0.5vw 0 0 0.5vw;
        border: 2.5px solid #C0AB9C;
        border-radius: 0.4vw;
        background: #00000000;
  }
  .ContactCon input::placeholder,textarea::placeholder{
    color: #C0AB9C;
  }
  .ContactCon span{
    position: relative;
    color: #C0AB9C;
    left: 5px;
    bottom: 2px;
    font-size: 1vw;
  }
  .ContactCon .formCard {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 34vw;
    height: 25vw;
    border-radius: 1vw;
    background-color: #FFDFC8;
    z-index: 10;
    transform: translate(-50%, -50%);
  }
  .ContactCon .formCard img {
    position: relative;
    top: 2vw;
    width: 11vw;
    height: 11vw;
  }
  .ContactCon .formCard button img {
    top: 0vw;
    width: 100%;
    height: 100%;
  }
  .ContactCon .formCard p {
    position: relative;
    top: 3vw;
    font-weight: bold;
    color: #384162;
  }
  .ContactCon .formCard button {
    position: relative;
    top: 5vw;
    height: 3vw; width: 8vw;
    font-size: 1vw; font-weight: bold;
    color: #384162;
    background-color: #FFB37F;
    border: none;
    border-radius: 1vw;
    z-index: 1;
  }
  .ContactCon .formCard button:hover {
    box-shadow: 0 0 10px #FFB37F;
  }
  .ContactCon .formCard button.closeButton {
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 3vw;
    height: 3vw;
    background: none;
    border: none;
    cursor: pointer;
    color: #384162;
    box-shadow: none;
  }
  .ContactCon .FormBack {
    position: absolute;
    top: -10vw;
    left: 0vw;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    display: none;
  }
@media (max-width: 900px) {
  .ContactCon {
    position: relative;
    top: 14vw;
    height: 279vw;
    padding: 0 18vw 0 18vw;
  }
  .ContactCon .text1 {
    font-size: 6vw;
    margin: 0 0 2vw 0;
  }
  .ContactCon .text2 {
    font-size: 4vw;
    margin: 0 0 3vw 0;
  }

  .ContactCon .Text .form-container {
    position: relative;
    left: -9vw;
    display: flex;
    flex-direction: column;
  }
  .ContactCon .align-left{
    position: relative;
  }
  .ContactCon .align-right {
    position: relative;
  }
  .ContactCon .form-group.align-left .tittle, .Slider p {
    position: relative;
    font-size: 4vw;
    padding: 15px 1px 3px 1px;
  }
  .ContactCon span {
      top: 0vw;
      left: 5px;
      bottom: 2px;
      font-size: 3.5vw;
  }
  .ContactCon .Slider .checkbox-group span
  ,.ContactCon .align-right .checkbox-group2 span {
    top: -0.5vw;
    left: 2vw;
  }
  .ContactCon .Text .inputCont{
    width: 87vw;
    height: 12vw;
    font-size: 4vw;
    border-radius: 2vw;
    padding: 10px;
    margin: 1.5vw 0vw 0vw -2vw;
  }
  .ContactCon .Slider .checkbox-group {
    width: 85vw;
    height: 15vw;
  }

  .ContactCon .align-right .rightBoxDemand, .rightBoxDemand2 {
    position: relative;
    font-size: 4vw;
    padding: 15px 1px 3px 1px;
  }
  .ContactCon .align-right .rightBoxDemand2{
    top: 20vw;
  }
  .ContactCon .align-right .checkbox-group2{
    width: 95vw;
    height: 30vw;
  }
  .align-right #reqText{
    width: 87vw;
    height: 25vw;
    left: -2vw;
    font-size: 4vw;
    border-radius: 2vw;
    padding: 2vw 2vw 0 2vw;
  }
  .ContactCon form button{
    left: 38vw;
    width: 30vw;
    height: 10vw;
    font-size: 4vw;
    border-radius: 3vw;
  }
  .ContactCon .formCard {
    top: 50%;
    left: 50%;
    width: 80vw;
    height: 100vw;
    border-radius: 2vw;
  }
  .ContactCon .formCard img {
    top: 10vw;
    width: 50vw;
    height: 50vw;
  }
  .ContactCon .formCard p {
    top: 13vw;
  }
    .ContactCon form button {
    top: 32vw;
    }
  .ContactCon .formCard button {
    top: 20vw;
  }
  .ContactCon .formCard button.closeButton {
    top: 5vw;
    right: 5vw;
    width: 4vw;
    height: 4vw;
  }
  #vehicle1,#vehicle2,#vehicle3, #vehicle4,#vehicle5,#vehicle6, #vehicle7,#vehicle8,#vehicle9,
  #vehicle11,#vehicle12,#vehicle13, #vehicle14,#vehicle15,#vehicle16, #vehicle17,
  #vehicle18,#vehicle19{
    width: 4vw;
    height: 4vw;
    margin-right:0vw;
  }
  .CheckBox09 textarea {
    top: 2vw;
  }
}
</style>
