<template>
    <div class="Contact">
      <div class="top"/>
        <ContactContent/>
    </div>

</template>

<script>
import ContactContent from '@/components/ContactContent.vue';

export default {
  name: 'ContactView',
  components: {
    ContactContent,
  },
};
</script>

<style scoped>
.Contact{
  position: relative;
  height: 80vw;
  cursor:url('https://www.flaticon.com/free-icon/new-moon_686700?term=circle&related_id=686700');
  overflow-x: hidden;
  background-image: url('/public/data/BackGround/ContactBackGround.png');
  background-size: 100% auto;
}

.Contact .top{
  height: 10vw;
}
@media (max-width: 900px) {
  .Contact{
    top: 13vw;
    height: 130vh;
    background-position: center;
    background-size: 100% 100%;
    background-image: url('/public/data/BackGround/Phone/phone_contact.png');
  }
}
</style>
