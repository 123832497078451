<template>
    <div class="WorksContent" >
        <p class="Title">作品列表</p>
        <p>作品匯總，影視精彩一覽無遺！</p>
    <div class="list" ref="imageList" :style="{ transform: 'translateX(' + offsetX + 'vw)' }">
                <div v-for="index in 5" :key="index" :class="`Img${index}`"
                    @click="toggleZoom(index)"
                    @keydown="handleKeyDown"
                    @mouseenter="handleMouseEnter(index)"
                    @mouseleave="handleMouseLeave(index)"
                    @focusin="handleFocusIn(index)"
                    @focusout="handleFocusOut(index)"
                    tabindex="0" class="image-container">
                    <div class="back"/>
                    <img :src="getImagePath(index)" alt="Contact Image"
                    :class="{ zoomed: isZoomed(index) , hovered: isHovered(index) }">
                    <p :class="{ zoomed: isZoomed(index) }">
                        {{ Pic.text[index - 1] }}</p>
                </div>
        </div>
                <div class="RightView"/>
        <div class="Button">
          <button class="left" @click="handleLeftButtonClick"
                        :style="{ display: LeftButtonDisplay }">
              <img src="../data/Home/arrow-up.png" alt="Contact Image">
            </button>
          <button class="right" @click="handleRightButtonClick"
                        :style="{ display: RightButtonDisplay }">
            <img src="../data/Home/arrow-up.png" alt="Contact Image">
            </button>
        </div>
    </div>
    <div>
        <WorksCard1 v-if="CardIndex === 1" />
        <WorksCard2 v-if="CardIndex === 2" />
        <WorksCard3 v-if="CardIndex === 3" />
        <WorksCard4 v-if="CardIndex === 4" />
        <WorksCard5 v-if="CardIndex === 5" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import activeBtn from '@/App.vue';
import WorksCard1 from './WorksCard1.vue';
import WorksCard2 from './WorksCard2.vue';
import WorksCard3 from './WorksCard3.vue';
import WorksCard4 from './WorksCard4.vue';
import WorksCard5 from './WorksCard5.vue';

export default {
    props: {
        zoomedIndex: {
            type: Number,
            default: 1,
        },
    },
    components: {
        WorksCard1,
        WorksCard2,
        WorksCard3,
        WorksCard4,
        WorksCard5,
    },
    data() {
        return {
            CardIndex: this.zoomedIndex,
            hoveredIndex: null,
            currentIndex: 1,
            WorkVideo: this.$route.params.WorkVideo || 1,
            offsetX: 1, // Initial offset
            itemWidth: 100,
            LeftButtonDisplay: 'none',
            Pic: {
                imagePaths: [
                    'data/works/Computer/image1.png',
                    'data/works/Computer/image2.png',
                    'data/works/Computer/image3.png',
                    'data/works/Computer/image4.png',
                    'data/works/Computer/image5.png',
                ],
                text: [
                    '廣告影片',
                    '形象影片',
                    '產品介紹',
                    '活動紀錄',
                    '人物專訪',
                ],
            },
        };
    },
      computed: {
        isMobileDevice() {
            return window.innerWidth <= 900;
        },
        dynamicImagePaths() {
            return this.isMobileDevice ? [
                'data/works/Phone/image1.png',
                'data/works/Phone/image2.png',
                'data/works/Phone/image3.png',
                'data/works/Phone/image4.png',
                'data/works/Phone/image5.png',
            ] : this.Pic.imagePaths;
        },
    },

    methods: {
        isZoomed(index) {
            return this.CardIndex === index;
        },
        isHovered(index) {
            return this.CardIndex === index;
        },
        toggleZoom(index) {
            if (this.isZoomed(index)) {
                this.CardIndex = null;
                this.WorkVideo = index;
                console.log(index);
            } else {
                this.CardIndex = index;
                this.WorkVideo = index;
            }
        },
        handleMouseEnter(index) {
            this.hoveredIndex = index;
        },
        handleMouseLeave(index) {
            this.hoveredIndex = null;
        },
        handleFocusIn(index) {
            this.hoveredIndex = index;
        },
        handleFocusOut(index) {
            this.hoveredIndex = null;
        },
        getImagePath(index) {
            return this.dynamicImagePaths[index - 1];
        },
        handleLeftButtonClick() {
            console.log('right', this.offsetX);
            if (this.offsetX < 0) {
                this.offsetX += this.itemWidth / 4;
                this.LeftButtonDisplay = this.offsetX < 0 ? 'block' : 'none';
                this.RightButtonDisplay = this.offsetX < 0 ? 'none' : 'block';
            }
        },

        handleRightButtonClick() {
            console.log('left', this.offsetX);
            if (this.offsetX > 0) {
                this.offsetX -= this.itemWidth / 4;
                this.LeftButtonDisplay = this.offsetX < 0 ? 'block' : 'none';
                this.RightButtonDisplay = this.offsetX < 0 ? 'none' : 'block';
            }
        },
    },
};
</script>
<style scoped>
.WorksContent {
    position: relative;
    padding: 2% 2% 2% 4%;
}
.WorksContent .Title {
    color: #FFB37F;
    font-weight: bolder;
    font-size: 3vw;
}
.WorksContent p {
    color: #C0AB9C;
    font-weight: bolder;
}
.WorksContent .list {
    position: relative;
    left: 7vw;
    padding: 0 10vw 0 0;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    transition: transform 0.3s ease; /* Optional: Add a smooth transition effect */
    white-space: nowrap;
}
.WorksContent .list .image-container {
    position: relative;
}
.WorksContent .list img {
    margin: 2vw;
    width: 17vw;
    height: 24vw;
    border-radius: 10px;
    transition: transform 0.5s ease;
    background-size: cover;
}

.WorksContent .list p {
    position: absolute;
    font-weight: bolder;
    color: #9EADFA;
    top: 18vw;
    width: 17vw;
    margin: 2vw;
    font-size: 2vw;
    bottom: 0;
    z-index: 10;
    transition: transform 0.5s ease;
}
.WorksContent .list img.zoomed,
.WorksContent .list p.zoomed {
    color: #FFB37F;
    transform: scale(1.1);
}
.WorksContent .list p.zoomed {
    font-weight: bolder;
    transform: translateY(1vw) scale(1.2);
}
.WorksContent .list img:hover + p {
    transform: translateY(1vw) scale(1.2);
}
.Button button {
    position: absolute;
    top: 20vw;
    width: 4vw;
    height: 4vw;
    font-size: 2.5vw;
    font-weight: bolder;
    color: #384162;
    background-color: #ffffffa6;
    cursor: pointer;
    border: none;
    border-radius: 10vw;
}
.WorksContent .Button .left {
  left: 3vw;
}
.WorksContent .Button .left img {
    position: relative;
    right: 0.15vw;
    width: 2vw;
    height: 1.5vw;
  transform: rotate(270deg);
}
.WorksContent .Button .right {
  right: 3vw;
}
.WorksContent .Button .right img {
    position: relative;
    right: -0.15vw;
    width: 2vw;
    height: 1.5vw;
    transform: rotate(90deg);
}
@media (max-width: 900px) {
    .WorksContent {
        top: 14vw;
        height: 44vw;
        padding: 2% 2% 2% 4%;
    }
    .WorksContent .Title {
        font-size: 6vw;
    }
    .WorksContent .list {
        left: 0vw;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        touch-action: pan-x;
    }
    .WorksContent .list::-webkit-scrollbar {
        display: none;
    }
    .WorksContent .list .back ,.WorksContent .list img {
        margin: 15px 10px;
        width: 24vw;
        height: 18vw;
        border-radius: 10px;
    }
    .WorksContent .list .back {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.56);
        z-index: 1;
    }
    .WorksContent .list img {
        transition: transform 0.5s ease;
    }
    .WorksContent .list img.zoomed {
        border: 1vw solid #FFB37F;
    }
    .WorksContent .list p {
        top: 9vw;
        left: 4vw;
        font-weight: normal;
        font-size: 3.5vw;
        color: #FFB37F;
    }
    .WorksContent .Button{
        display: none;
    }
    .WorksContent .RightView {
            position: absolute;
            top: 0vw;
            height: 85%;
            width: 10vw;
            right: 0vw;
            background: linear-gradient(to right, rgba(0, 0, 0, 0) 60%,
            #1c223876 90%, #1C2238);

        }
}
</style>
